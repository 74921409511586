import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { TablerIcon } from "@tabler/icons";

import { sleep } from "../../../services/utils";

interface Props {
  text: string;
  OuterIcon: TablerIcon;
  InnerIcon?: TablerIcon;
  link: string;
}

function CompHomeButton(props: Props) {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      className="h-full w-full m-0 font-light text-xl"
      onClick={() => sleep(200).then(() => navigate(props.link))}
    >
      <Container>
        <div className="relative m-auto h-44 w-44">
          <props.OuterIcon
            style={{ strokeWidth: "0.25px" }}
            className="h-full w-full"
          />
          {props.InnerIcon ? (
            <div className="absolute bottom-0 right-0 bg-slate-200 rounded-full h-20 w-20">
              <props.InnerIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 stroke-1 h-16 w-16" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <span
          className="text-3xl m-auto"
          style={{ fontFamily: "Outfit, Arial, sans-serif" }}
        >
          {props.text}
        </span>
      </Container>
    </Button>
  );
}

export default CompHomeButton;
