import React, { useState, useEffect } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

// import { fakeVessels, VesselDetails } from "../../../services/fake-vessel-list";
import { kssVessels, VesselDetails } from "../../../services/kss-vessel-list";

import { randomInt } from "../../../services/utils";

interface DataFetcherProps {
  // hooks to provide status of data fetching
  setLoaded: (loaded: boolean) => void;
  children: React.ReactNode;
}

interface Column {
  field: string;
  headerName: string;
  hideable?: boolean;
  type?: string;
  width?: number;
  renderCell?: (params: GridRenderCellParams) => React.ReactNode;
}
type Columns = Column[];

// dynamic columns have been commented out but should be added back in
// after data is available or faked.

const allColumns = [
  { field: "name", headerName: "Name" },
  { field: "type", headerName: "Type" },
  { field: "flag", headerName: "Flag" },
  { field: "imo_number", headerName: "IMO" },
  // { field: "position", headerName: "Position" },
  // { field: "destination", headerName: "Destination" },
  // { field: "status", headerName: "Status" },
  // { field: "load", headerName: "Load" },
  { field: "dwt", headerName: "Deadweight", type: "number" },
  { field: "gt", headerName: "Gross Tonnage", type: "number" },
  { field: "cu.m.", headerName: "Cubic Capacity", type: "number" },
  { field: "speed", headerName: "Speed", type: "number" },
  { field: "main_engine_imo_tier", headerName: "Main Engine IMO Tier" },
  { field: "ownership", headerName: "Ownership" },
  { field: "age", headerName: "Age", type: "number" },
  { field: "fuel_type", headerName: "Fuel Type" },
  { field: "%_active_days", headerName: "% Active Days", type: "number" },
  { field: "%_idle_days", headerName: "% Idle Days", type: "number" },
  { field: "reported_eedi", headerName: "Reported EEDI" },
];

export const DataContext = React.createContext<VesselDetails[]>([]);
export const AllColumnsContext = React.createContext<Columns>([]);

function processColumn(column: Column): Column {
  // use renderCell to link to dashboard

  if (column.field === "name") {
    return {
      ...column,
      width: 200,
      hideable: false,
      renderCell: (params) => <span>{params.value}</span>,
    };
  }
  return {
    ...column,
    width: 150,
  };
}

const DataFetcher = ({ setLoaded, children }: DataFetcherProps) => {
  const [vesselData, setVesselData] = useState<VesselDetails[]>([]);
  const [columns, setColumns] = useState<Columns>([]);
  useEffect(() => {
    setTimeout(() => setLoaded(true), randomInt(1000, 4000));
    setVesselData(kssVessels);
    setColumns(allColumns.map((column) => processColumn(column)));
  }, [vesselData, setLoaded]); // will probably change to fetch data periodically
  return (
    <DataContext.Provider value={vesselData}>
      <AllColumnsContext.Provider value={columns}>
        {children}
      </AllColumnsContext.Provider>
    </DataContext.Provider>
  );
};

export default DataFetcher;
