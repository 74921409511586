import { RegisterOptions } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";

import {
  FieldName,
  vesselMasterReference,
  fuelMasterReference,
  fieldNameReference,
} from "./eexi-topology";

export interface FormDetailProps<Name, DefaultValue> {
  name: Name;
  label: string;
  options?: object;
  InputProps?: Record<string, any>;
  helperText?: string;
  defaultValue?: DefaultValue;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

type FormDetails = Partial<
  Record<FieldName, FormDetailProps<FieldName, string>>
>;

const formatUnit = (name: string, Comp: JSX.Element) => (
  <Tooltip title={name}>
    <InputAdornment position="end">{Comp}</InputAdornment>
  </Tooltip>
);
const units = {
  t: formatUnit("metric tonnes", <>MT</>),
  kW: formatUnit("kilowatts", <>kW</>),
  kt: formatUnit("knots", <>kn</>),
  m: formatUnit("metres", <>m</>),
  m3: formatUnit(
    "cubic metres",
    <>
      m<sup>3</sup>
    </>
  ),
  g_kWh: formatUnit("grams per kilowatt hour", <>g/kWh</>),
};

export const formDetails: FormDetails = {
  vesselType: {
    name: "vesselType",
    label: fieldNameReference["vesselType"],
    options: vesselMasterReference,
    rules: {},
  },
  fuelTypeMain: {
    name: "fuelTypeMain",
    label: fieldNameReference["fuelTypeMain"],
    defaultValue: "hfo",
    options: fuelMasterReference,
    rules: {},
  },
  fuelTypeAux: {
    name: "fuelTypeAux",
    label: fieldNameReference["fuelTypeAux"],
    defaultValue: "hfo",
    options: fuelMasterReference,
    rules: {},
  },
  conversionFactorMain: {
    name: "conversionFactorMain",
    label: fieldNameReference["conversionFactorMain"],
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  conversionFactorAux: {
    name: "conversionFactorAux",
    label: fieldNameReference["conversionFactorAux"],
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  deadweight: {
    name: "deadweight",
    label: fieldNameReference["deadweight"],
    InputProps: {
      endAdornment: units.t,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  grossTonnage: {
    name: "grossTonnage",
    label: fieldNameReference["grossTonnage"],
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  mcrMain: {
    name: "mcrMain",
    label: fieldNameReference["mcrMain"],
    InputProps: {
      endAdornment: units.kW,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  mppMain: {
    name: "mppMain",
    label: fieldNameReference["mppMain"],
    InputProps: {
      endAdornment: units.kW,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  mcrAux: {
    name: "mcrAux",
    label: fieldNameReference["mcrAux"],
    InputProps: {
      endAdornment: units.kW,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  mppAux: {
    name: "mppAux",
    label: fieldNameReference["mppAux"],
    InputProps: {
      endAdornment: units.kW,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  sfcMain: {
    name: "sfcMain",
    label: fieldNameReference["sfcMain"],
    helperText: "At 75% MCR (Main Engine)",
    InputProps: {
      endAdornment: units.g_kWh,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  sfcAux: {
    name: "sfcAux",
    label: fieldNameReference["sfcAux"],
    helperText: "At 50% MCR (Aux Engine)",
    InputProps: {
      endAdornment: units.g_kWh,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  cargoCapacity: {
    name: "cargoCapacity",
    label: fieldNameReference["cargoCapacity"],
    InputProps: {
      endAdornment: units.t,
    },
    // helperText: "Enter 0 if N/A",
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  lightweight: {
    name: "lightweight",
    label: fieldNameReference["lightweight"],
    InputProps: {
      endAdornment: units.t,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  mouldedDraught: {
    name: "mouldedDraught",
    label: fieldNameReference["mouldedDraught"],
    helperText: "At summer load line",
    InputProps: {
      endAdornment: units.m,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  lpp: {
    name: "lpp",
    label: fieldNameReference["lpp"],
    InputProps: {
      endAdornment: units.m,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  breadth: {
    name: "breadth",
    label: fieldNameReference["breadth"],
    InputProps: {
      endAdornment: units.m,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  volumetricDisplacement: {
    name: "volumetricDisplacement",
    label: fieldNameReference["volumetricDisplacement"],
    InputProps: {
      endAdornment: units.m3,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  blockCoefficient: {
    name: "blockCoefficient",
    label: fieldNameReference["blockCoefficient"],
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
  referenceVelocity: {
    name: "referenceVelocity",
    label: fieldNameReference["referenceVelocity"],
    helperText: "At summer load line, at 75% of MCR",
    InputProps: {
      endAdornment: units.kt,
    },
    rules: {
      validate: {
        isNumber: (v: { value: string }) =>
          !isNaN(parseFloat(v.value)) || "Must be a number",
      },
    },
  },
};
