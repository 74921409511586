import { useContext, useEffect, useState } from "react";
import { DataContext } from "./comp-data-fetcher";
import obtainFakeUpdates from "../services/fake-update-messages";
import { VesselDetails } from "../../../services/fake-vessel-list";
import Logo from "../../../../../logo.svg";
import sum from "lodash/sum";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";

import { IconBell } from "@tabler/icons";

// obtain an overall summary of updates from data

const UpdatesBar = () => {
  const data = useContext(DataContext);
  //   const uuidMap: { [key: string]: VesselDetails } = data.reduce(
  //     (acc, curr) => ({ ...acc, [curr.uuid]: curr }),
  //     {}
  //   );
  //   // const uuids = Object.keys(uuidMap);
  //   const uuids = data.map((vessel) => vessel.uuid);

  const [updates, setUpdates] = useState<{ [key: string]: string[] }>({});
  const [numUpdates, setNumUpdates] = useState(0);
  const [uuidMap, setUuidMap] = useState<{ [key: string]: VesselDetails }>({});
  const [displayAlerts, setDisplayAlerts] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDisplayAlerts(open);
    };

  useEffect(() => {
    // extract context into local state
    if (data.length) {
      setUuidMap(
        data.reduce((acc, curr) => ({ ...acc, [curr.uuid]: curr }), {})
      );
      // setUuidMap is non-blocking.
      // Hence, at this point, uuidMap would not be updated yet (still empty)
    }
  }, [data]);
  useEffect(() => {
    // cascade from context updated local variables
    const fakeUpdates = obtainFakeUpdates(Object.keys(uuidMap));
    setUpdates(fakeUpdates);
    const numUpdates = sum(
      Object.values(fakeUpdates).map((vesselUpdates) => vesselUpdates.length)
    );
    setNumUpdates(numUpdates);
  }, [uuidMap]);

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <Badge badgeContent={numUpdates} color="warning">
          <IconBell />
        </Badge>
      </Button>
      <Drawer
        anchor="right"
        open={displayAlerts}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { width: "20%" },
        }}
      >
        <Stack spacing={2} className="p-3">
          <Typography
            variant="h5"
            fontWeight={500}
            fontFamily="Outfit, Roboto, Arial, sans-serif"
          >
            Updates
          </Typography>
          {Object.keys(updates).map((uuid) => (
            <div key={uuid}>
              {[
                updates[uuid].length > 0 && uuidMap[uuid].name,
                updates[uuid].map((update) => (
                  <Alert severity="info" key={uuid + update}>
                    {update}
                  </Alert>
                )),
              ]}
            </div>
          ))}
        </Stack>
      </Drawer>
    </>
  );
};

export default UpdatesBar;
