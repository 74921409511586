export interface VesselDetails {
  name: string;
  flag: string;
  uuid: string;
  type: string;
  imo_number: string;
  dwt: number;
  gt: number;
  "cu.m.": number;
  loa: number;
  draft: number;
  age: number;
  speed: number | null;
  power_type: string;
  environmental_equipment?: string;
  main_engine: string;
  propulsor: string;
  auxiliary?: string;
  eco_status: string;
  scrubber_status: string;
  builder_country: string;
  ests: string;
  fuel_type: string;
  reported_eedi: string;
  main_engine_imo_tier: string;
  breadth: number | null;
  consumption: number | null;
  "%_idle_days": number | null;
  "%_active_days": number | null;
  ownership: string;
  yard: string;
}

export const kssVessels: VesselDetails[] = [
  {
    power_type: "Diesel 4-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P500 at 500cu.m/hr.",
    main_engine:
      "1 x Diesel - Hanshin 6LH41L - 4-stroke 6-cyl. 410mm x800mm bore/stroke 2,427mkW total at 225rpm.",
    auxiliary:
      "2 x Aux. Diesel Gen. - Yanmar 6N165L-SW - 4-stroke 6-cyl. 165mm x 232mm bore/stroke 970mkW total at 1,200rpm driving 2 x AC generator(s) at 900ekW total, (1,124kVA total) 445V at 60Hz.",
    propulsor: "1 x Propeller (Aft Centre) (mechanical), 225rpm.",
    imo_number: "9809643",
    "cu.m.": 4240.0,
    age: 4.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "Exhaust Gas Economiser",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 3492.0,
    loa: 91.4,
    draft: 5.86,
    breadth: 14.4,
    speed: 13.2,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "Oil & Chemical",
    gt: 2993.0,
    yard: "Daesun",
    flag: "Panama",
    ownership: "Owned",
    name: "DUKE CHEMIST",
    uuid: "63568f9c-74e6-48b1-9fda-4ad1c6a02c06",
  },
  {
    power_type: "Diesel 4-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P500 at 500cu.m/hr.",
    main_engine:
      "1 x Diesel - Hanshin 6LH41L - 4-stroke 6-cyl. 410mm x800mm bore/stroke 2,427mkW total at 225rpm.",
    auxiliary:
      "2 x Aux. Diesel Gen. - Yanmar 6N165L-SW - 4-stroke 6-cyl. 165mm x 232mm bore/stroke 970mkW total at 1,200rpm driving 2 x AC generator(s) at 900ekW total, (1,124kVA total) 445V at 60Hz.",
    propulsor: "1 x Propeller (Aft Centre) (mechanical), 225rpm.",
    imo_number: "9809655",
    "cu.m.": 4216.0,
    age: 4.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "Exhaust Gas Economiser",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 3473.0,
    loa: 91.4,
    draft: 5.86,
    breadth: 14.4,
    speed: 13.2,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "Oil & Chemical",
    gt: 2993.0,
    yard: "Daesun",
    flag: "Panama",
    ownership: "Owned",
    name: "E CHEMIST",
    uuid: "7c1b03c9-3771-462c-8bcb-d9db4774cc57",
  },
  {
    power_type: "Diesel 4-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P500 at 500cu.m/hr.",
    main_engine:
      "1 x Diesel - Hanshin 6LH41L - 4-stroke 6-cyl. 410mm x800mm bore/stroke 2,427mkW total at 225rpm.",
    auxiliary:
      "2 x Aux. Diesel Gen. - Yanmar 6N165L-SW - 4-stroke 6-cyl. 165mm x 232mm bore/stroke 970mkW total at 1,200rpm driving 2 x AC generator(s) at 900ekW total, (1,124kVA total) 445V at 60Hz.",
    propulsor: "1 x Propeller (Aft Centre) (mechanical), 225rpm.",
    imo_number: "9809667",
    "cu.m.": 4300.0,
    age: 3.8,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "Exhaust Gas Economiser",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 3479.0,
    loa: 91.4,
    draft: 5.86,
    breadth: 14.4,
    speed: 13.2,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "Oil & Chemical",
    gt: 2993.0,
    yard: "Daesun",
    flag: "Panama",
    ownership: "Owned",
    name: "FALCON CHEMIST",
    uuid: "e1daab27-27d1-41be-bc0f-dcfe98863c4d",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems. 1 x Selective Catalytic Reduction - NOx NoNOx. 1 x BWTS - Ballast Water Treatment System. 3 x Selective Catalytic Reduction - NOx NoNOx.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,253mkW total at 93.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x AC generator(s) at 3,600ekW total, (4,500kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 93.20rpm.",
    imo_number: "9892391",
    "cu.m.": 84045.0,
    age: 1.1,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 53919.0,
    loa: 223.71,
    draft: 12.12,
    breadth: 32.26,
    speed: null,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 48858.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS ARES",
    uuid: "9c23a101-2313-423b-ae63-77bef281e155",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems. 1 x Selective Catalytic Reduction - NOx NoNOx. 1 x BWTS - Ballast Water Treatment System. 3 x Selective Catalytic Reduction - NOx NoNOx.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,253mkW total at 93.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x AC generator(s) at 3,600ekW total, (4,500kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 93.20rpm.",
    imo_number: "9903762",
    "cu.m.": 84045.0,
    age: 0.9,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 53759.0,
    loa: 223.71,
    draft: 12.12,
    breadth: 32.26,
    speed: null,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 48858.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS BARBAROSSA",
    uuid: "5b3e9005-e2de-4034-8a87-2149cc41b796",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P1500 at 1500cu.m/hr - 2020 installation year.",
    main_engine:
      "1 x Diesel - Mitsubishi 7UEC60LS - 2-stroke 7-cyl. 600mm x2200mm bore/stroke 12,356mkW total at 100rpm.",
    auxiliary: "3 x Aux. Diesel Gen. - 4-stroke  driving 3 x ac generator(s).",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 100rpm.",
    imo_number: "9321225",
    "cu.m.": 78912.0,
    age: 16.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "Japan",
    ests: "",
    fuel_type: "Conventional (VLS IFO)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 49997.0,
    loa: 230.0,
    draft: 10.8,
    breadth: 36.6,
    speed: 16.7,
    consumption: 44.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 46129.0,
    yard: "Mitsubishi HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS FRIEND",
    uuid: "5f5cd687-d719-46b8-8866-2503a338aa91",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems. 1 x Selective Catalytic Reduction - NOx NoNOx. 1 x BWTS - Ballast Water Treatment System. 3 x Selective Catalytic Reduction - NOx NoNOx.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,253mkW total at 93.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x AC generator(s) at 3,600ekW total, (4,500kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 93.20rpm.",
    imo_number: "9887451",
    "cu.m.": 84021.0,
    age: 1.2,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 53780.0,
    loa: 223.71,
    draft: 12.12,
    breadth: 32.26,
    speed: null,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 48858.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS GABRIELA",
    uuid: "c25e3fce-bbc8-4c4e-9b63-824bb5618f20",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems. 1 x Selective Catalytic Reduction - NOx NoNOx. 1 x BWTS - Ballast Water Treatment System. 3 x Selective Catalytic Reduction - NOx NoNOx.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,253mkW total at 93.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x AC generator(s) at 3,600ekW total, (4,500kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 93.20rpm.",
    imo_number: "9892406",
    "cu.m.": 84000.0,
    age: 1.0,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 53780.0,
    loa: 223.71,
    draft: 12.12,
    breadth: 32.26,
    speed: null,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 48858.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS GALA",
    uuid: "6a19beb1-f562-4bfb-8851-67c74e9e8185",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems. 1 x Selective Catalytic Reduction - NOx NoNOx. 1 x BWTS - Ballast Water Treatment System. 3 x Selective Catalytic Reduction - NOx NoNOx.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,253mkW total at 93.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x AC generator(s) at 3,600ekW total, (4,500kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 93.20rpm.",
    imo_number: "9906922",
    "cu.m.": 84000.0,
    age: 0.4,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 53780.0,
    loa: 223.71,
    draft: 12.12,
    breadth: 32.26,
    speed: null,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 48858.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS GHAZI",
    uuid: "b7da3f11-3930-4de0-87f6-e73be72b2d20",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - TechCross ECS-300 - Electro-Cleen\u2122 at 300cu.m/hr - 2019 installation year.",
    main_engine:
      "1 x Diesel - Mitsubishi 5UEC33LSII - 2-stroke 5-cyl. 330mm x1050mm bore/stroke 2,700mkW total at 210rpm.",
    auxiliary:
      "2 x Aux. Diesel Gen. - Yanmar 6NY16L-EN - 4-stroke 6-cyl. 160mm x 200mm bore/stroke 1,060mkW total at 1,200rpm driving 2 x AC generator(s) at 960ekW total, (1,200kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 210rpm.",
    imo_number: "9527001",
    "cu.m.": 3700.0,
    age: 10.8,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "Japan",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 3830.0,
    loa: 98.0,
    draft: 5.41,
    breadth: 16.0,
    speed: 15.3,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "PRESSURE",
    gt: 3444.0,
    yard: "Shitanoe",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS ONE",
    uuid: "542445cb-276e-4190-b615-22f327691881",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed).",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems - 2020 installation year. 2 x BWTS - Ballast Water Treatment System - TechCross ECS-1000 - Electro-Cleen\u2122 at 1000cu.m/hr.",
    main_engine:
      "1 x Diesel - Mitsubishi 7UEC60LSII - 2-stroke 7-cyl. 600mm x2300mm bore/stroke 14,315mkW total at 105rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - Daihatsu 6DK-20 - 4-stroke 6-cyl. 200mm x 300mm bore/stroke 2,850mkW total at 900rpm driving 3 x AC generator(s) at 2,640ekW total, (3,300kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 105rpm.",
    imo_number: "9522415",
    "cu.m.": 78912.0,
    age: 10.1,
    eco_status: "Non - Eco",
    scrubber_status: "Fitted",
    builder_country: "Japan",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 49999.0,
    loa: 230.0,
    draft: 10.8,
    breadth: 36.6,
    speed: 16.7,
    consumption: 49.0,
    "%_idle_days": null,
    "%_active_days": 95.6,
    type: "VLGC",
    gt: 46173.0,
    yard: "Mitsubishi HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS POWER",
    uuid: "8ade8d89-f79f-47cc-a824-43c83e9b6b6f",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "2 x BWTS - Ballast Water Treatment System - TechCross ECS-600 - Electro-Cleen\u2122 at 600cu.m/hr.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6S50MC-C8.1 - 2-stroke 6-cyl. 500mm x2000mm bore/stroke 9,480mkW total at 127rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,960mkW total at 900rpm driving 3 x ac generator(s) at 2,549ekW total, (3,186kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 105rpm.",
    imo_number: "9645358",
    "cu.m.": 35098.0,
    age: 9.0,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 26477.0,
    loa: 174.0,
    draft: 10.42,
    breadth: 28.0,
    speed: 16.5,
    consumption: 35.1,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "MGC",
    gt: 22814.0,
    yard: "Hyundai Mipo",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS QUANTUM",
    uuid: "ac15f6de-5318-4cfe-8722-093cc634c499",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed).",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - Alfa Laval PureSOx - 2018 installation year. 1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6S60MC-C8.2 - 2-stroke 6-cyl. 600mm x2400mm bore/stroke 12,600mkW total at 92.70rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9670004",
    "cu.m.": 84144.0,
    age: 8.2,
    eco_status: "Non - Eco",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 54732.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS STAR",
    uuid: "218bae19-34bf-48ac-9862-c601e9718670",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed).",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems - 2019 installation year. 1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6S60MC-C8.2 - 2-stroke 6-cyl. 600mm x2400mm bore/stroke 12,600mkW total at 92.70rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9693549",
    "cu.m.": 84144.0,
    age: 7.5,
    eco_status: "Non - Eco",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 54684.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS SUMMIT",
    uuid: "08633748-59fb-42f7-9190-7ab8736cde4a",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,400mkW total at 92.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9754824",
    "cu.m.": 84000.0,
    age: 5.8,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (VLS IFO)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 51568.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS TIGERS",
    uuid: "81d0c175-52a0-4fa7-bb23-fdc64e547ebc",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "2 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P500 at 500cu.m/hr.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G50ME-B9.5 - 2-stroke 6-cyl. 500mm x2500mm bore/stroke 8,480mkW total at 97.40rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - Yanmar 6EY22LW - 4-stroke 6-cyl. 220mm x 320mm bore/stroke 2,640mkW total at 720rpm driving 3 x AC generator(s) at 2,400ekW total, (3,000kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9770531",
    "cu.m.": 38611.0,
    age: 5.0,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 28672.0,
    loa: 180.0,
    draft: 10.41,
    breadth: 28.4,
    speed: 16.0,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "MGC",
    gt: 25049.0,
    yard: "Hyundai Mipo",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS UTOPIA",
    uuid: "76c56e67-c46e-4570-8b86-cef413055e47",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "2 x BWTS - Ballast Water Treatment System - PANASIA Glo-En\u2122 P500 at 500cu.m/hr.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G50ME-B9.5 - 2-stroke 6-cyl. 500mm x2500mm bore/stroke 8,480mkW total at 97.40rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - Yanmar 6EY22LW - 4-stroke 6-cyl. 220mm x 320mm bore/stroke 2,640mkW total at 720rpm driving 3 x AC generator(s) at 2,400ekW total, (3,000kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9774197",
    "cu.m.": 38611.0,
    age: 4.8,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 28702.0,
    loa: 180.0,
    draft: 10.41,
    breadth: 28.4,
    speed: 16.0,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "MGC",
    gt: 25004.0,
    yard: "Hyundai Mipo",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS VENUS",
    uuid: "4a34eec8-4efb-49f5-a99c-9548c7c638d7",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - HHI Power Systems - 2020 installation year. 1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,400mkW total at 92.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9788992",
    "cu.m.": 84000.0,
    age: 5.0,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 54468.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 99.2,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS WISDOM",
    uuid: "a3b2f0af-6e10-4e10-ae03-54b2489f02c3",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,400mkW total at 92.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9800099",
    "cu.m.": 84000.0,
    age: 4.8,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (VLS IFO)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 54645.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS YOUNG",
    uuid: "ea02a446-31dd-4719-a8bd-4f5072c97fba",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Sewage Treatment Plant. 2 x BWTS - Ballast Water Treatment System - HHI-EMD (HiMSEN) Hi-Ballast.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G60ME-C9.5 - 2-stroke 6-cyl. 600mm x2790mm bore/stroke 12,400mkW total at 92.20rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - HHI-EMD (HiMSEN) 6H21/32 - 4-stroke 6-cyl. 210mm x 320mm bore/stroke 3,600mkW total at 900rpm driving 3 x Hyundai Electric HFC7 568-84K - AC generator(s) at 3,420ekW total, (4,275kVA total) at 60Hz.",
    propulsor:
      "1 x FP Propeller (Aft Centre) (mechanical), HHI-EMD (HiMSEN), \u00d87.40m.",
    imo_number: "9795543",
    "cu.m.": 84000.0,
    age: 4.5,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (VLS IFO)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier II",
    dwt: 54675.0,
    loa: 225.0,
    draft: 12.02,
    breadth: 36.6,
    speed: 16.9,
    consumption: 52.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "VLGC",
    gt: 47454.0,
    yard: "Hyundai HI",
    flag: "Panama",
    ownership: "Owned",
    name: "GAS ZENITH",
    uuid: "65047fb2-a643-4f4d-8303-83730a9c632b",
  },
  {
    power_type:
      "Diesel 2-Stroke. BWTS (Fitted). Scrubber (Installed). Eco \u2013 Electronic Engine Modern.",
    environmental_equipment:
      "1 x Exhaust Scrubber - SOx - PANASIA PaSOx\u2122. 3 x Selective Catalytic Reduction - NOx Y-SCR. 1 x BWTS - Ballast Water Treatment System - S&SYS(Samsung HI) PuriMar. 1 x Selective Catalytic Reduction - NOx - Unknown.",
    main_engine:
      "1 x Diesel - MAN B. & W. 6G50ME-C9.6 - 2-stroke 6-cyl. 500mm x2500mm bore/stroke 6,875mkW total at 79rpm.",
    auxiliary:
      "3 x Aux. Diesel Gen. - Yanmar 6EY22ALWS - 4-stroke 6-cyl. 220mm x 320mm bore/stroke 2,910mkW total at 900rpm driving 3 x ac generator(s) at 2,700ekW total, (3,375kVA total) at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9859193",
    "cu.m.": 52000.0,
    age: 1.8,
    eco_status: "Eco \u2013 Electronic Engine Modern",
    scrubber_status: "Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "Conventional (IFO 380)",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "Tier III",
    dwt: 49999.0,
    loa: 183.09,
    draft: 13.32,
    breadth: 32.2,
    speed: 14.4,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 91.8,
    type: "Oil Product",
    gt: 30087.0,
    yard: "Samsung HI",
    flag: "Panama",
    ownership: "Owned",
    name: "HARMONY CHEMIST",
    uuid: "2cd77a96-b020-437a-ae19-65dfc5fc06e7",
  },
  {
    power_type: "Steam Turbine.",
    main_engine: "1 x Steam Turb. - Mitsubishi MS28-2 - 20,594mkW total.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical), 83rpm.",
    imo_number: "9061928",
    "cu.m.": 130600.0,
    age: 26.5,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 71041.0,
    loa: 264.0,
    draft: 12.02,
    breadth: 43.0,
    speed: 19.0,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "LNG",
    gt: 90004.0,
    yard: "HHIC",
    flag: "South Korea",
    ownership: "Invested",
    name: "HL PYEONG TAEK",
    uuid: "f03d5abe-1932-4dd8-a7e6-ca48ca2ca8c0",
  },
  {
    power_type: "Steam Turbine. BWTS (Fitted).",
    environmental_equipment:
      "6 x BWTS - Ballast Water Treatment System - TechCross ECS-1000 - Electro-Cleen\u2122 at 1000cu.m/hr - 2021 installation year.",
    main_engine: "1 x Steam Turb. - Mitsubishi MS28-2 - 19,638mkW total.",
    auxiliary:
      "3 x Aux. Diesel Gen. - 4-stroke  driving 3 x AC generator(s) at 8,100ekW total, (10,125kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9075333",
    "cu.m.": 125000.0,
    age: 25.3,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 71684.0,
    loa: 274.0,
    draft: 11.77,
    breadth: 47.2,
    speed: 18.5,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 92.9,
    type: "LNG",
    gt: 103764.0,
    yard: "Hyundai HI",
    flag: "South Korea",
    ownership: "Invested",
    name: "HYUNDAI GREENPIA",
    uuid: "ddc05d3b-f743-4c6d-92b3-6b201c9b6772",
  },
  {
    power_type: "Steam Turbine.",
    main_engine: "1 x Steam Turb. - Mitsubishi MS28-2 - 19,638mkW total.",
    auxiliary:
      "3 x Aux. Diesel Gen. - 4-stroke  driving 3 x AC generator(s) at 8,100ekW total, (10,125kVA total) 450V at 60Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9018555",
    "cu.m.": 125182.0,
    age: 27.8,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 71909.0,
    loa: 273.0,
    draft: 11.75,
    breadth: 47.2,
    speed: 18.5,
    consumption: 143.0,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "LNG",
    gt: 103764.0,
    yard: "Hyundai HI",
    flag: "South Korea",
    ownership: "Invested",
    name: "HYUNDAI UTOPIA",
    uuid: "b94f425b-4500-4e68-af9e-3f6cee5e070f",
  },
  {
    power_type: "Diesel 2-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - TechCross ECS-300 - Electro-Cleen\u2122 at 300cu.m/hr - 2019 installation year.",
    main_engine:
      "1 x Diesel - Mitsubishi 6UEC33LSII - 2-stroke 6-cyl. 330mm x1050mm bore/stroke 3,400mkW total at 215rpm.",
    auxiliary: "2 x Aux. Diesel Gen. - 4-stroke  driving 2 x AC generator(s).",
    propulsor: "1 x Propeller (Aft Centre) (mechanical), 215rpm.",
    imo_number: "9415038",
    "cu.m.": 9396.0,
    age: 12.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "Japan",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 8623.0,
    loa: 112.0,
    draft: 7.61,
    breadth: 18.73,
    speed: 13.5,
    consumption: 16.1,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "Oil & Chemical",
    gt: 5376.0,
    yard: "Shitanoe",
    flag: "Panama",
    ownership: "Owned",
    name: "JOY CHEMIST",
    uuid: "3286f13b-30ca-4402-aad4-731d1f511b7c",
  },
  {
    power_type: "Diesel 4-Stroke. BWTS (Fitted).",
    environmental_equipment:
      "1 x BWTS - Ballast Water Treatment System - TechCross ECS-300 - Electro-Cleen\u2122 at 300cu.m/hr - 2020 installation year.",
    main_engine:
      "1 x Diesel - Hanshin 6LH41L - 4-stroke 6-cyl. 410mm x800mm bore/stroke 2,427mkW total at 225rpm.",
    propulsor: "1 x Propeller (Aft Centre) (mechanical), 225rpm.",
    imo_number: "9485538",
    "cu.m.": 4153.0,
    age: 12.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "Japan",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 3968.0,
    loa: 88.6,
    draft: 6.01,
    breadth: 14.6,
    speed: 13.2,
    consumption: 9.4,
    "%_idle_days": null,
    "%_active_days": 100.0,
    type: "Oil & Chemical",
    gt: 2583.0,
    yard: "Shitanoe",
    flag: "Panama",
    ownership: "Owned",
    name: "NURI CHEMIST",
    uuid: "0d3e81d5-3e9e-430e-bda2-c3480e960cd0",
  },
  {
    power_type: "Steam Turbine.",
    main_engine: "1 x Steam Turb. - Mitsubishi MS28-2 - 19,638mkW total.",
    auxiliary:
      "2 x Aux. Diesel Gen. - Wartsila 4-stroke 8R32 - 4-stroke 8-cyl. 320mm x 350mm bore/stroke 6,000mkW total at 750rpm driving 2 x ac generator(s) at 50Hz.",
    propulsor: "1 x FP Propeller (Aft Centre) (mechanical).",
    imo_number: "9038816",
    "cu.m.": 127125.0,
    age: 27.2,
    eco_status: "Non - Eco",
    scrubber_status: "Not Fitted",
    builder_country: "South Korea",
    ests: "",
    fuel_type: "",
    reported_eedi: "Not Reported",
    main_engine_imo_tier: "",
    dwt: 72020.0,
    loa: 274.0,
    draft: 11.7,
    breadth: 47.2,
    speed: 18.5,
    consumption: null,
    "%_idle_days": null,
    "%_active_days": 97.3,
    type: "LNG",
    gt: 103764.0,
    yard: "Hyundai HI",
    flag: "South Korea",
    ownership: "Invested",
    name: "YK SOVEREIGN",
    uuid: "cff65703-b124-4de1-b0f5-e1aa925bdf09",
  },
];
