import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { mapValues, pickBy, omitBy, isNil } from "lodash";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Alert from "@mui/material/Alert";

import CompEexiField from "./components/comp-eexi-field";
import CompEquationRenderer from "./components/comp-equation-renderer";

import { formDetails, FormDetailProps } from "./services/eexi-form-utils";
import {
  isNothing,
  Maybe,
  isTextFieldName,
  isJustNumericFieldName,
  isMaybeNumericFieldName,
  FieldName,
  isFieldName,
  Fields,
  UserFieldValue,
  UserFields,
  vesselMasterReference,
  FieldNodes,
  FieldValues,
} from "./services/eexi-topology";
import {
  displayCounter,
  backpropDependencies,
  evalNode,
} from "./services/eexi-graph-utils";

//---------------------- TYPE DEFINITIONS ----------------------//

// undefined if field is unassigned, NOTHING if field is not applicable
interface ResultSummary {
  isConformityRequired?: boolean;
  requiredEexi?: Fields["requiredEexi"];
  attainedEexi?: Fields["attainedEexi"];
  isCompliant?: Maybe<boolean>;
  requiredPowerMain?: Maybe<Fields["powerMain"]>;
  requiredEplSetting?: Maybe<number>;
}

// Numeric type guard
const isJustNumericValue = (
  value: Maybe<number> | null | undefined
): value is number => typeof value === "number";

const defaultValues = omitBy(
  mapValues(
    pickBy(formDetails, (val) => val !== undefined),
    (val) =>
      val.defaultValue
        ? {
            state: "default" as UserFieldValue["state"],
            value: val.defaultValue,
          }
        : { state: "default" as UserFieldValue["state"], value: "" }
  ),
  isNil
);

function eexiFixedPointIteration(
  getAttainedEexi: (
    powerMain: Fields["powerMain"],
    referenceVelocity: Fields["referenceVelocity"]
  ) => Fields["attainedEexi"] | null
) {
  const { requiredEexi } = nodeValueCache;
  let { attainedEexi, powerMain, referenceVelocity } = nodeValueCache;

  // Require all values to be numbers
  if (
    !isJustNumericValue(requiredEexi) ||
    !isJustNumericValue(attainedEexi) ||
    !isJustNumericValue(powerMain) ||
    !isJustNumericValue(referenceVelocity)
  )
    return {
      requiredPowerMain: null,
      requiredReferenceVelocity: null,
    }; // Should not occur

  // Loop until attainedEexi < requiredEexi (limit 100 times in case solution does not converge)
  for (let i = 0; i < 100 && attainedEexi > requiredEexi; i++) {
    powerMain *= ((0.9999 * requiredEexi) / attainedEexi) ** (3 / 2);
    referenceVelocity *= ((0.9999 * requiredEexi) / attainedEexi) ** (1 / 2);
    const computedAttainedEexi = getAttainedEexi(powerMain, referenceVelocity);
    if (isJustNumericValue(computedAttainedEexi)) {
      console.debug(`FPI #${i}`, {
        current: computedAttainedEexi,
        target: requiredEexi,
      });
      attainedEexi = computedAttainedEexi;
    } else
      return {
        requiredPowerMain: null,
        requiredReferenceVelocity: null,
      }; // Should not occur
  }
  return {
    requiredPowerMain: powerMain,
    requiredReferenceVelocity: referenceVelocity,
  };
}

const nodeValueCache: FieldValues = {};

console.debug("DEFAULT VALUES", defaultValues);

function RouteEexiCalculator() {
  // DP state elements for dependency topology
  const [nodes, setNodes] = useState<FieldNodes>({});
  const [hookedDisplayCounter, setHookedDisplayCounter] =
    useState(displayCounter);

  useEffect(
    () => console.debug("DISPLAY COUNTER UPDATED", hookedDisplayCounter),
    [hookedDisplayCounter]
  );

  const {
    handleSubmit,
    watch,
    getValues,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm<UserFields>({
    mode: "onSubmit",
    defaultValues,
  });

  const [vesselType, setVesselType] = useState<string>("");
  const [resultSummary, setResultSummary] = useState<ResultSummary>({
    isConformityRequired: false,
    attainedEexi: 0,
    isCompliant: true,
  });
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [equations, setEquations] = useState<string[]>([]);

  useEffect(() => {
    const _vesselType = getValues("vesselType")?.value;
    if (!!_vesselType && _vesselType in vesselMasterReference) {
      setVesselType(_vesselType);
      setNodes(vesselMasterReference[_vesselType].nodes);
    }
  }, [watch("vesselType")?.value]);

  useEffect(() => {
    if (nodes.requiredEexi !== undefined && nodes.attainedEexi !== undefined) {
      reset({ ...defaultValues, vesselType: getValues("vesselType") }); // Reset the form state
      for (const key in displayCounter) displayCounter[key as FieldName] = 0; // Reset the display counter
      backpropDependencies(
        "requiredEexi",
        nodes,
        "+",
        displayCounter,
        getValues
      );
      backpropDependencies(
        "attainedEexi",
        nodes,
        "+",
        displayCounter,
        getValues
      );
      console.debug("NODES CHANGED", displayCounter);
      setHookedDisplayCounter({ ...displayCounter });
    }
  }, [nodes]);

  console.debug("RENDER EEXI CALCULATOR");

  const handleFormSubmit: SubmitHandler<UserFields> = (
    formState: UserFields
  ) => {
    console.debug("FORMSTATE", formState);
    if (Object.values(errors).length === 0) {
      console.debug("CALCULATING");

      // Reset node value cache
      for (const cachedField in nodeValueCache) {
        if (isFieldName(cachedField)) {
          nodeValueCache[cachedField] = undefined;
        }
      }

      // Assign new values from form state to cache
      const submissionValues = getValues();
      Object.entries(submissionValues).forEach(([formField, formValue]) => {
        if (
          isFieldName(formField) &&
          formValue !== undefined &&
          !(
            nodes[formField]?.type === "optionalinput" &&
            formValue.state === "omitted"
          ) &&
          formValue.value !== ""
        ) {
          if (isTextFieldName(formField))
            nodeValueCache[formField] = formValue.value;
          if (
            isMaybeNumericFieldName(formField) ||
            isJustNumericFieldName(formField)
          ) {
            const submissionValue = formValue.value;
            if (submissionValue !== undefined)
              nodeValueCache[formField] = parseFloat(submissionValue);
          }
        }
      });
      console.debug("INITIAL CACHED VALUES", nodeValueCache);

      // Initialise equation cache
      const equationCache: string[] = [];

      const requiredEexi = evalNode(
        "requiredEexi",
        nodes,
        nodeValueCache,
        equationCache
      );
      const attainedEexi = evalNode(
        "attainedEexi",
        nodes,
        nodeValueCache,
        equationCache
      );
      setEquations(equationCache);

      if (requiredEexi === null || attainedEexi === null) {
        // TODO: Handle missing field error(s)
        setShowResults(false);
        return;
      }

      const getAttainedEexi = (
        powerMain: number,
        referenceVelocity: number
      ) => {
        nodeValueCache.attainedEexi = undefined;
        nodeValueCache.powerMain = powerMain;
        nodeValueCache.referenceVelocity = referenceVelocity;
        return evalNode("attainedEexi", nodes, nodeValueCache);
      };

      if (isNothing(requiredEexi)) {
        setResultSummary({
          isConformityRequired: false,
          attainedEexi,
        });
      } else if (attainedEexi > requiredEexi) {
        const { requiredPowerMain, requiredReferenceVelocity } =
          eexiFixedPointIteration(getAttainedEexi);
        const mcrMain = nodeValueCache.mcrMain;
        console.debug({ requiredPowerMain, requiredReferenceVelocity });
        if (requiredPowerMain === null || !isJustNumericValue(mcrMain)) return;
        setResultSummary({
          isConformityRequired: true,
          requiredEexi,
          attainedEexi,
          isCompliant: false,
          requiredPowerMain,
          requiredEplSetting: requiredPowerMain / mcrMain,
        });
      } else {
        setResultSummary({
          isConformityRequired: true,
          requiredEexi,
          attainedEexi,
          isCompliant: true,
        });
      }
      setShowResults(true);
      return true;
    }
    setShowResults(false);
    return false;
  };

  return (
    <Container className="my-5 mobi:min-w-[400px]">
      <div className="flex">
        <Typography
          variant="h3"
          fontWeight={500}
          fontFamily="Outfit, Roboto, Arial, sans-serif"
          className="m-auto"
        >
          <span className="font-extralight">eexi</span>calculator
        </Typography>
      </div>
      <Alert severity="info" className="my-3">
        Mouse over a unit to view its full name.
      </Alert>
      <Grid container className="min-h-full">
        <Grid
          item
          key="griditem-form"
          xs={6}
          className="mobi:min-w-full px-3 border-r-[1px]"
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2} direction="column">
              <Grid item key="griditem-vesselType" className="mobi:min-w-full">
                {!!formDetails["vesselType"] && (
                  <CompEexiField
                    {...formDetails["vesselType"]}
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    nodes={nodes}
                    getValues={getValues}
                    setHookedDisplayCounter={setHookedDisplayCounter}
                  />
                )}
              </Grid>
              {!!vesselType &&
                Object.entries(hookedDisplayCounter)
                  .filter(([_, val]) => val > 0)
                  .map(([field, _]) => {
                    if (!isFieldName(field)) return null;
                    const formDetailProps:
                      | FormDetailProps<FieldName, unknown>
                      | undefined = formDetails[field];
                    if (formDetailProps === undefined) {
                      console.warn(`Missing field props for "${field}.`);
                      return null;
                    }
                    return (
                      <Grid
                        item
                        key={"griditem-" + field}
                        className="mobi:min-w-[400px]"
                      >
                        <CompEexiField
                          {...formDetailProps}
                          control={control}
                          trigger={trigger}
                          errors={errors}
                          nodes={nodes}
                          getValues={getValues}
                          setHookedDisplayCounter={setHookedDisplayCounter}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
            <div className="flex justify-center my-5">
              <Button
                variant="outlined"
                type="submit"
                disabled={Object.values(errors).some((error) => error)}
                color="inherit"
              >
                Calculate
              </Button>
            </div>
          </form>
        </Grid>
        <Grid
          item
          key="griditem-results"
          xs={6}
          className="mobi:min-w-full px-3"
        >
          <Collapse in={showResults}>
            <Typography
              variant="h5"
              fontWeight={500}
              fontFamily="Outfit, Roboto, Arial, sans-serif"
              className="mb-3"
            >
              Results
            </Typography>
            <TableContainer
              component={Paper}
              className="m-auto w-11/12 mobi:min-w-full"
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Attained EEXI</TableCell>
                    <TableCell>
                      {isJustNumericValue(resultSummary.attainedEexi)
                        ? (resultSummary.attainedEexi + 0.005).toFixed(2)
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      Is EEXI Conformity Required?
                    </TableCell>
                    <TableCell>
                      {resultSummary.isConformityRequired ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  {resultSummary.isConformityRequired && (
                    <>
                      <TableRow>
                        <TableCell variant="head">Required EEXI</TableCell>
                        <TableCell>
                          {isJustNumericValue(resultSummary.requiredEexi)
                            ? (resultSummary.requiredEexi - 0.005).toFixed(2)
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">
                          Compliant at 75% MCR?
                        </TableCell>
                        <TableCell>
                          {resultSummary.isCompliant ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                      {!resultSummary.isCompliant && (
                        <>
                          <TableRow>
                            <TableCell variant="head">
                              Compliant Main Engine Power
                            </TableCell>
                            <TableCell>
                              {isJustNumericValue(
                                resultSummary.requiredPowerMain
                              )
                                ? `${(
                                    resultSummary.requiredPowerMain - 0.005
                                  ).toFixed(2)} kW`
                                : null}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              Compliant EPL Setting
                            </TableCell>
                            <TableCell>
                              {isJustNumericValue(
                                resultSummary.requiredEplSetting
                              )
                                ? (
                                    resultSummary.requiredEplSetting * 100 -
                                    0.5
                                  ).toFixed(0)
                                : 0}
                              % MCR
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="flex my-5 justify-center">
              {!showSteps && (
                <Button variant="outlined" onClick={() => setShowSteps(true)}>
                  Show Steps
                </Button>
              )}
            </div>
            <Collapse in={showSteps}>
              <CompEquationRenderer equations={equations} />
            </Collapse>
          </Collapse>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RouteEexiCalculator;
