import PageLogin from "./pages/page-login/page-login";
import PageReportUpload from "./pages/page-report-upload/page-report-upload";
import PageVesselDashboard from "./pages/page-vessel-dashboard/page-vessel-dashboard";
import PageVesselList from "./pages/page-vessel-list/page-vessel-list";
import PageWorldView from "./pages/page-world-view/page-world-view";

const pages: {
  [key: string]: (props: { [key: string]: any }) => JSX.Element;
} = {
  PageLogin,
  PageReportUpload,
  PageVesselDashboard,
  PageVesselList,
  PageWorldView,
};

export { pages };
