import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IconFileUpload, IconFileDownload } from "@tabler/icons";

import CompProducerHeader from "../../components/comp-producer-header";
import CompSegmentHeader from "./components/comp-segment-header";
import CompTableBox from "./components/comp-table-box";
import CompGraphBox from "./components/comp-graph-box";

// import { fakeVessels, VesselDetails } from "../../services/fake-vessel-list";
import { kssVessels, VesselDetails } from "../../services/kss-vessel-list";

import map from "../../../../assets/simple-maps-bw-south-china-sea.png";

const ProfileBox = ({ vesselData }: { vesselData: VesselDetails }) => {
  return (
    <Container className="h-full border-2">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {vesselData && <CompSegmentHeader text={vesselData?.name} />}
          {vesselData?.type}
          <br />
          {vesselData?.flag}
          <br />
          IMO {vesselData?.imo_number}
        </Grid>
        <Grid item xs={6}>
          <img
            src={map}
            alt="map location"
            className="relative object-right h-25 w-full"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const UploadBox = () => {
  return (
    <Container className="relative h-full border-2">
      <CompSegmentHeader text="Upload" />
      <div className="relative top-1/2 -translate-y-1/2">
        <IconFileUpload className="relative left-1/2 -translate-x-1/2 w-16 h-16 text-slate-300"></IconFileUpload>
        <br />
        <Container className="relative left-1/2 -translate-x-1/2 p-0 text-center">
          Drop reports here
          <br />
          <Typography variant="caption" className="italic">
            Last Report: ~20 min ago
          </Typography>
        </Container>
      </div>
    </Container>
  );
};

const DownloadBox = () => {
  return (
    <Container className="relative h-full border-2">
      <CompSegmentHeader text="Download" />
      <div className="relative top-1/2 -translate-y-1/2">
        <IconFileDownload className="relative left-1/2 -translate-x-1/2 w-16 h-16 text-slate-300"></IconFileDownload>
        <br />
        <Container className="relative left-1/2 -translate-x-1/2 p-0 text-center">
          Get CII report
        </Container>
      </div>
    </Container>
  );
};

// If find is commonly used, move this to service.
// Using find is more likely to be a temporary solution till api call.
function ensure<T>(
  arg: T | undefined | null,
  msg: string = "A value was supposed to be returned"
): T {
  if (arg === undefined || arg === null) {
    throw new TypeError(msg);
  }

  return arg;
}

function PageVesselDashboard() {
  const { uuid: vesselUuid } = useParams();
  // find returns undefined because it can potentially be a collection, single, or empty
  const vesselData = ensure(
    kssVessels.find((elem) => elem.uuid === vesselUuid)
  );

  // TODO: Change to simulate a real API call
  // const vesselData = await getVesselData(vesselUuid); or something like that
  // likely to be in a useEffect loop

  console.log(vesselData);
  return (
    <>
      <CompProducerHeader
        histPages={[
          { name: "MarinaNet", link: "/" },
          { name: "Vessels", link: "/producer/vessels" },
        ]}
        currPage={{ name: vesselData?.name ?? "Dashboard" }}
      />
      <Grid container spacing={1} sx={{ height: "90vh" }} className="p-3">
        <Grid item xs={6} className="h-1/3">
          <ProfileBox vesselData={vesselData} />
        </Grid>
        <Grid item xs={3} className="h-1/3">
          <UploadBox />
        </Grid>
        <Grid item xs={3} className="h-1/3">
          <DownloadBox />
        </Grid>
        <Grid item xs={8} className="h-2/3">
          <CompGraphBox />
        </Grid>
        <Grid item xs={4} className="h-2/3">
          <CompTableBox
            mainEngineDetails={vesselData.main_engine}
            auxEngineDetails={vesselData.auxiliary}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PageVesselDashboard;
