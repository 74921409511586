import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import RouteHome from "./routes/route-home/route-home";
import RouteProducer from "./routes/route-producer/route-producer";
import RouteEexiCalculator from "./routes/route-eexi-calcuator/route-eexi-calculator";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="producer/*" element={<RouteProducer />} />
        <Route path="eexi" element={<RouteEexiCalculator />} />
        <Route index element={<RouteHome />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
