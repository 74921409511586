interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

export default function TabPanel({ children, value, index }: TabPanelProps) {
  // choice: use hidden or use boolean to hide.
  // if hidden, might be faster to change tabs. But more memory?
  return (
    <div hidden={value !== index} key={index.toString()}>
      {children}
    </div>
  );
}
