import { randomInt } from "../../../services/utils";

const codeMessages = [
  "No updates",
  "Departing to: ",
  "Arrived at: ",
  "Berthing: ",
  "CII forecast changed: ",
  "CII changed: ",
  "Report Overdue",
];

const fakePortsList = [
  "Busan, Korea",
  "Hokkaido, Japan",
  "Jurong, Singapore",
  "Sydney, Australia",
];
const fakeCII = ["A", "B", "C", "D", "E"];

const fakeMessageSuffix = [
  "",
  fakePortsList[randomInt(0, fakePortsList.length - 1)],
  fakePortsList[randomInt(0, fakePortsList.length - 1)],
  fakePortsList[randomInt(0, fakePortsList.length - 1)],
  fakeCII[randomInt(0, fakeCII.length - 1)],
  fakeCII[randomInt(0, fakeCII.length - 1)],
  "",
];

export default function obtainFakeUpdates(vesselUuids: string[]) {
  // generate at most 2 updates for each vessel
  let updates: { [key: string]: string[] } = {};
  vesselUuids.forEach((uuid) => {
    updates[uuid] = [];
    for (let i = 0; i < randomInt(0, 2); i++) {
      const code = randomInt(1, 6);
      const update = codeMessages[code] + fakeMessageSuffix[code];
      if (!updates[uuid].includes(update)) updates[uuid].push(update);
    }
  });
  return updates;
}
