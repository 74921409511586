import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

import CompProducerHeader from "../../components/comp-producer-header";
import DataFetcher from "./components/comp-data-fetcher";
import TabBar from "./components/comp-tab-bar";
import UpdatesBar from "./components/comp-updates-bar";
import { Typography } from "@mui/material";

import { IconCloudUpload } from '@tabler/icons';

const drawerWidth = 240;

function PageVesselList() {
  const [loaded, setLoaded] = useState(true);

  // need to define the height of box else datagrid won't show.
  return (
    <Box className="flex">
      <CompProducerHeader
        width={`calc(100% - ${drawerWidth}px)`}
        histPages={[{ name: "MarinaNet", link: "/" }]}
        currPage={{ name: "Vessels" }}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "grey.300",
            border: "none",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar className="flex justify-center">
          <Typography
            variant="h4"
            fontSize="18px"
            fontWeight="700"
            color="grey.800"
          >
            MarinaNet
          </Typography>
        </Toolbar>
      </Drawer>
      <Box component="main" className="px-[48px] min-h-[600px] flex-grow">
        <Toolbar className="mb-[20px] h-[80px]" />
        <Box className="flex items-center mb-[20px]">
          <div>Vessels List (2,290)</div>
          <div className="flex-grow" />
          <div>
            <Button
              variant="outlined"
              sx={{
                borderWidth: "1px",
                color: "secondary2.700",
                backgroundColor: "secondary2.50",
              }}
            >
              <Typography
                variant="h6"
                fontSize="14px"
                fontWeight="700"
                className="normal-case"
              >
                Export CSV
              </Typography>
            </Button>
            <Button variant="contained">
              <Typography
                variant="h6"
                fontSize="14px"
                fontWeight="700"
                className="normal-case"
              >
                Upload CSV File
              </Typography>
            </Button>
          </div>
        </Box>
        <DataFetcher setLoaded={setLoaded}>
          {loaded ? (
            <>
              <div className="flex justify-end">
                <UpdatesBar />
              </div>
              <TabBar />
            </>
          ) : (
            <span>Loading...</span>
          )}
        </DataFetcher>
      </Box>
    </Box>
  );
}

export default PageVesselList;
