import { Typography } from "@mui/material";

interface Props {
  text?: string;
}

function CompSegmentHeader(props: Props) {
  return (
    <Typography
      variant="h5"
      fontWeight={500}
      fontFamily="Outfit, Roboto, Arial, sans-serif"
    >
      {props.text ?? ""}
    </Typography>
  );
}

export default CompSegmentHeader;
