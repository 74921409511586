import { useState } from "react";
import ChartistGraph from "react-chartist";
import merge from "lodash/merge";

interface Props {
  label: string;
  series: number[];
  chartOptions: object;
}

function CompTimeseriesGraph(props: Props) {
  const chartData = {
    labels: [props.label],
    series: [props.series],
  };
  const baseOptions = {
    axisY: {
      showGrid: true,
    },
    width: "100%",
    height: "45vh",
    lineSmooth: false,
    showArea: true,
    low: 0,
    high: Math.ceil(Math.max(...props.series)),
  };

  return (
    <>
      <ChartistGraph
        data={chartData}
        options={merge(baseOptions, props.chartOptions)}
        type="Line"
      />
    </>
  );
}

export default CompTimeseriesGraph;
