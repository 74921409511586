function PageLogin(props: { [key: string]: any }) {
  return (
    <>
      Login works!
      <br />
      <div onClick={() => props.setPageName("PageVesselList")}>Test</div>
    </>
  );
}

export default PageLogin;
