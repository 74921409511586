import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// there should be a service that generates the tables based on the data instead

export const SampleCrewTable = () => {
  return (
    <Table className="border-2">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>John Smith</TableCell>
          <TableCell>Captain</TableCell>
          <TableCell>None</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Randal White</TableCell>
          <TableCell>Engineer</TableCell>
          <TableCell>None</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Stephanie Sanders</TableCell>
          <TableCell>Chef</TableCell>
          <TableCell>None</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const SamplePortCallTable = () => {
  return (
    <Table className="border-2">
      <TableHead>
        <TableRow>
          <TableCell>Port</TableCell>
          <TableCell>Arrival</TableCell>
          <TableCell>Departure</TableCell>
          <TableCell>In Port</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Singapore</TableCell>
          <TableCell>Jan 5, 22:31</TableCell>
          <TableCell>Jan 6, 02:12</TableCell>
          <TableCell>3h 41m</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Busan</TableCell>
          <TableCell>Jan 12, 13:20</TableCell>
          <TableCell>Jan 12, 16:35</TableCell>
          <TableCell>3h 15m</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Singapore</TableCell>
          <TableCell>Jan 20, 02:43</TableCell>
          <TableCell>Jan 20, 06:54</TableCell>
          <TableCell>4h 11m</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
