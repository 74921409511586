import Grid from "@mui/material/Grid";
import {
  IconUser,
  IconShoppingCart,
  IconBuildingStore,
  IconCoin,
} from "@tabler/icons";
import CompHomeButton from "./components/comp-home-button";

// import * as locales from '@mui/material/locale';

interface Props {}

function RouteHome(props: Props) {
  // console.log(locales);
  return (
    <Grid
      container
      spacing={3}
      columns={3}
      direction="row"
      alignItems="center"
      justifyContent="center"
      className="h-screen w-screen p-5"
    >
      <Grid item xs={1} className="h-2/3">
        <CompHomeButton
          OuterIcon={IconUser}
          InnerIcon={IconShoppingCart}
          text="Customer"
          link="/customer"
        />
      </Grid>
      <Grid item xs={1} className="h-2/3">
        <CompHomeButton
          OuterIcon={IconUser}
          InnerIcon={IconBuildingStore}
          text="Producer"
          link="/producer"
        />
      </Grid>
      <Grid item xs={1} className="h-2/3">
        <CompHomeButton
          OuterIcon={IconUser}
          InnerIcon={IconCoin}
          text="Investor"
          link="/investor"
        />
      </Grid>
    </Grid>
  );
}

export default RouteHome;
