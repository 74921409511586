import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  Control,
  UseFormTrigger,
  UseFormGetValues,
  FieldError,
} from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { FormDetailProps } from "../services/eexi-form-utils";
import { FieldName, UserFields, FieldNodes } from "../services/eexi-topology";
import {
  DisplayCounter,
  displayCounter,
  backpropDependencies,
} from "../services/eexi-graph-utils";

type InputComponentProps<Name> = Omit<
  FormDetailProps<Name, unknown>,
  "defaultValue"
>;

function CompEexiField({
  name,
  label,
  options,
  InputProps,
  helperText,
  rules,
  control,
  trigger,
  errors,
  nodes,
  getValues,
  setHookedDisplayCounter,
}: InputComponentProps<FieldName> & {
  control: Control<UserFields>;
  trigger: UseFormTrigger<UserFields>;
  errors: Partial<
    Record<FieldName, { value?: FieldError; state?: FieldError }>
  >;
  nodes: FieldNodes;
  getValues: UseFormGetValues<UserFields>;
  setHookedDisplayCounter: Dispatch<SetStateAction<DisplayCounter>>;
}) {
  const node = nodes[name];
  InputProps ??= {};
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: {
            value: (value) =>
              !(
                value !== undefined &&
                value.state === "default" &&
                value.value === ""
              ),
          },
        }}
        render={(renderProps) => (
          <div className="flex align-middle">
            <TextField
              {...renderProps.field}
              variant="standard"
              size="small"
              key={name}
              select={!!options}
              error={!!errors[name]}
              id={name}
              label={label}
              InputProps={InputProps}
              helperText={
                !!errors[name]?.value
                  ? errors[name]?.value?.message
                  : helperText
              }
              className="flex-grow mr-2"
              disabled={renderProps.field.value?.state === "omitted"}
              value={renderProps.field.value?.value ?? ""}
              onChange={(e) =>
                renderProps.field.onChange({
                  state: renderProps.field.value?.state,
                  value: e.target.value,
                })
              }
            >
              {options
                ? Object.entries(options).map(
                    ([key, value]: [string, { name: string }]) => (
                      <MenuItem key={`${name}-${key}`} value={key}>
                        {value.name}
                      </MenuItem>
                    )
                  )
                : null}
            </TextField>
            {node !== undefined && node.type === "optionalinput" ? (
              <FormControlLabel
                label={<small>SKIP</small>}
                labelPlacement="bottom"
                className="h-[90%] my-auto mx-0"
                control={
                  <Checkbox
                    {...renderProps.field}
                    size="small"
                    className="p-0"
                    checked={renderProps.field.value?.state === "omitted"}
                    onChange={async (e) => {
                      if (e.target.checked) {
                        await renderProps.field.onChange({
                          state: "omitted",
                          value: renderProps.field.value?.value,
                        });
                        backpropDependencies(
                          name,
                          nodes,
                          "+",
                          displayCounter,
                          getValues
                        );
                      } else {
                        backpropDependencies(
                          name,
                          nodes,
                          "-",
                          displayCounter,
                          getValues
                        );
                        await renderProps.field.onChange({
                          state: "default",
                          value: renderProps.field.value?.value,
                        });
                      }
                      await trigger(name);
                      setHookedDisplayCounter({ ...displayCounter });
                    }}
                  />
                }
              />
            ) : null}
          </div>
        )}
      />
    </FormControl>
  );
}

export default CompEexiField;
