import { useState, useEffect } from "react";

import Container from "@mui/material/Container";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import Typography from "@mui/material/Typography";

import CompSegmentHeader from "./comp-segment-header";

import { IconChevronDown } from "@tabler/icons";

import { SamplePortCallTable, SampleCrewTable } from "./sample-tables";
import { StringLiteralLike } from "typescript";

interface AccordionElements {
  title: string;
  content: JSX.Element;
}

interface TableBoxProps {
  mainEngineDetails: string;
  auxEngineDetails?: String;
}

const TableBox = ({ mainEngineDetails, auxEngineDetails }: TableBoxProps) => {
  const [data, setData] = useState<AccordionElements[]>([]);

  const fakeData = [
    {
      title: "Port Calls",
      content: (
        <Container>
          <SamplePortCallTable />
        </Container>
      ),
    },
    {
      title: "Ship Specifications",
      content: (
        <Container>
          <h2>Main Engine</h2>
          <p>{mainEngineDetails}</p>
        </Container>
      ),
    },
    {
      title: "Cargo Log",
      content: <Container>Details</Container>,
    },
    {
      title: "Crew Log",
      content: (
        <Container>
          <SampleCrewTable />
        </Container>
      ),
    },
  ];

  // handleChange and expanded are intentionally set up to maintain
  // only 1 "active" accordion at a time.
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    setData(fakeData);
  }, []);

  return (
    <Container className="h-full border-l-2 overflow-auto">
      <CompSegmentHeader text="Tables" />
      <br />
      {data.map((elem, index) => {
        return (
          <Accordion
            expanded={expanded === index.toString()}
            onChange={handleChange(index.toString())}
            disableGutters={true}
            key={elem.title}
          >
            <AccordionSummary expandIcon={<IconChevronDown />}>
              <Typography>{elem.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{elem.content}</AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
};

export default TableBox;
