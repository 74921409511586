import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MathJaxContext } from "better-react-mathjax";

import AppRouter from "./AppRouter";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Manrope",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 14,
    },
    palette: {
      primary: {
        main: "#121621",
        light: "#383c48",
        dark: "#000000",
        contrastText: "#fff",
        50: "#F8FAFC",
        100: "#EAEFF6",
        200: "#CDDAE8",
        300: "#88A6C9",
        400: "#4179BE",
        500: "#06448E",
        600: "#053672",
        700: "#042955",
        800: "#042955",
        900: "#010E1C",
      },
      secondary: {
        main: "#09dba9",
        contrastText: "#000",
        50: "#EDFCF9",
        100: "#CEF8EE",
        200: "#9DF1DD",
        300: "#6BE9CB",
        400: "#3AE2BA",
        500: "#09DBA9",
        600: "#07AF87",
        700: "#058365",
        800: "#022C22",
        900: "#02221A",
      },
      secondary2: {
        main: "#00ccff",
        contrastText: "#000",
        50: "#F0FCFF",
        100: "#CCF5FF",
        200: "#99EBFF",
        300: "#66E0FF",
        400: "#3C9AC4",
        500: "#00CCFF",
        600: "#0093B8",
        700: "#0082A3",
        800: "#004557",
        900: "#00313D",
      },
      grey: {
        50: "#F9FAFB",
        100: "#F2F4F7",
        200: "#E4E7EC",
        300: "#D0D5DD",
        400: "#B3BAC7",
        500: "#98A2B3",
        600: "#667085",
        700: "#475467",
        800: "#344054",
        900: "#1D2939",
      },
      success: {
        main: "#98A2B3",
        50: "#ecfdf3",
        100: "#d1fadf",
        200: "#a6f4c5",
        300: "#6ce9a6",
        400: "#32d583",
        500: "#12b76a",
        600: "#039855",
        700: "#027a48",
        800: "#05603a",
        900: "#054f31",
      },
      warning: {
        main: "#f79009",
        50: "#fffaeb",
        100: "#fef0c7",
        200: "#fedf89",
        300: "#fec84b",
        400: "#fdb022",
        500: "#f79009",
        600: "#dc6803",
        700: "#b54708",
        800: "#93370d",
        900: "#7a2e0e",
      },
      error: {
        main: "#f04438",
        50: "#fffbfa",
        100: "#fef3f2",
        200: "#ffcdca",
        300: "#fda29b",
        400: "#fa7066",
        500: "#f04438",
        600: "#d92d20",
        700: "#b42318",
        800: "#912018",
        900: "#7a271a",
      },
      info: {
        main: "#2e90fa",
        50: "#eff8ff",
        100: "#d1e9ff",
        200: "#b2ddff",
        300: "#84caff",
        400: "#53b1fd",
        500: "#2e90fa",
        600: "#1570ef",
        700: "#175cd3",
        800: "#1849a9",
        900: "#194185",
      },
      background: { paper: "#fbfbfb" },
      // contrastThreshold: 3,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <MathJaxContext
        config={{
          loader: { load: ["[tex]/action"] },
          tex: { packages: { "[+]": ["action"] } },
        }}
      >
        <AppRouter />
      </MathJaxContext>
    </ThemeProvider>
  );
}

export default App;
