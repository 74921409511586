// import { useEffect } from "react";
import DeckGL from "@deck.gl/react";
import { LineLayer } from "@deck.gl/layers";
import { Map } from "react-map-gl";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidGhlZ2FicmllbGsiLCJhIjoiY2wzMG50b2VyMDJrZjNqcGQ3c2xka3l3ayJ9.b5H3vdEtAEjAATNaKemW0w";

// Viewport settings
const INITIAL_VIEW_STATE = {
  latitude: 1.3521,
  longitude: 103.8198,
  zoom: 10,
  pitch: 0,
  bearing: 0,
};

function PageWorldView() {
  const layers: LineLayer<
    unknown,
    {
      id: string;
      data: {
        sourcePosition: number[];
        targetPosition: number[];
      }[];
    }
  >[] = [];

  return (
    <DeckGL
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      layers={layers}
    >
      <Map
        style={{ width: 600, height: 400 }}
        mapStyle="mapbox://styles/thegabrielk/cl30nyb55000215jy3p86pva3"
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      />
    </DeckGL>
  );
}

export default PageWorldView;
