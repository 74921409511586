import { zipObject } from "lodash";

import {
  Maybe,
  TextFieldName,
  isTextFieldName,
  JustNumericFieldName,
  isJustNumericFieldName,
  MaybeNumericFieldName,
  isMaybeNumericFieldName,
  FieldName,
  fieldNames,
  Fields,
  FieldNodes,
  FieldValues,
} from "./eexi-topology";

export type DisplayCounter = Record<FieldName, number>;

// DP state elements for dependency topology
export const displayCounter = zipObject(
  fieldNames,
  fieldNames.map(() => 0)
) as DisplayCounter;

// Returns false on expected behaviour
// Returns true if missing nodes were found
// If true, reinitiatise the corrupted displayCounter state with zeros
export function backpropDependencies(
  nodeName: FieldName,
  nodes: FieldNodes,
  op: "+" | "-",
  displayCounter: Record<FieldName, number>,
  getValues: (nodeName: FieldName) => {
    state: "default" | "omitted" | undefined;
  }
): boolean {
  const node = nodes[nodeName];
  if (!node) {
    console.warn(`No node named "${nodeName}"!`);
    return true;
  }
  if (
    node.type === "onlyinput" ||
    (node.type === "optionalinput" && getValues(nodeName)?.state === "default")
  ) {
    console.debug(`BACKPROP: [Base] ${op}1 to ${nodeName}`);
    if (op === "+") displayCounter[nodeName]++;
    if (op === "-") displayCounter[nodeName]--;
    return false;
  } else {
    // Sum missing nodes from parent nodes
    // Must ensure no cycles in the graph, or this recursive call can loop infinitely
    return node.parents.some((parentName: FieldName) => {
      console.debug(`BACKPROP: [Recursive] ${nodeName} -> ${parentName}`);
      return backpropDependencies(
        parentName,
        nodes,
        op,
        displayCounter,
        getValues
      );
    });
  }
}

// Overloaded function signatures for various types
export function evalNode(
  nodeName: TextFieldName,
  nodes: FieldNodes,
  nodeValueCache: FieldValues,
  equationCache?: string[]
): string | null;
export function evalNode(
  nodeName: JustNumericFieldName,
  nodes: FieldNodes,
  nodeValueCache: FieldValues,
  equationCache?: string[]
): number | null;
export function evalNode(
  nodeName: MaybeNumericFieldName,
  nodes: FieldNodes,
  nodeValueCache: FieldValues,
  equationCache?: string[]
): Maybe<number> | null;

// Implementation
export function evalNode(
  nodeName: FieldName,
  nodes: FieldNodes,
  nodeValueCache: FieldValues,
  equationCache?: string[]
): unknown {
  const node = nodes[nodeName];
  const cachedValue = nodeValueCache[nodeName];
  if (cachedValue !== undefined) return cachedValue;
  if (node === undefined) {
    console.error(`EVALNODE: Missing node information for "${nodeName}"!`);
    return null;
  }
  if (node.type === "onlyinput") {
    console.warn(
      `EVALNODE: Field value for "${nodeName}" was not assigned to cache!`
    );
    return null;
  }
  const parentValues = node.parents.map((parentName) => {
    if (isTextFieldName(parentName))
      return evalNode(parentName, nodes, nodeValueCache, equationCache);
    if (isJustNumericFieldName(parentName))
      return evalNode(parentName, nodes, nodeValueCache, equationCache);
    if (isMaybeNumericFieldName(parentName))
      return evalNode(parentName, nodes, nodeValueCache, equationCache);
  });
  if (parentValues.some((value) => value === null)) return null;
  else {
    const parentNodes = zipObject(node.parents, parentValues);
    const { value: nodeValue, equations } = node.computation(
      parentNodes as Required<Partial<Fields>>
    );
    // console.debug(`EVALNODE: Evaluated node "${nodeName}": ${nodeValue}`);
    (
      nodeValueCache as Partial<
        Record<FieldName, string | number | Maybe<number>>
      >
    )[nodeName] = nodeValue;
    if (equationCache !== undefined) equationCache.push(...equations);
    return nodeValue;
  }
}
