import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext, AllColumnsContext } from "./comp-data-fetcher";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridRowParams,
  GridFilterModel,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { IconSettings } from "@tabler/icons";

export interface Filter {
  columnField: string;
  operatorValue?: string | undefined;
  value?: string | number;
}

interface DataListProps {
  // Own properties
  columnStates: { [key: string]: boolean };
  filters: Filter[];
  updateColumns: (columnStates: { [key: string]: boolean }) => void;
  updateFilters: (filters: Filter[]) => void;
}

const SettingsButton = ({
  handleClick,
}: {
  handleClick: React.MouseEventHandler;
}) => (
  <Button onClick={handleClick}>
    <IconSettings />
  </Button>
);

const Toolbar = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <GridToolbarContainer>
      <SettingsButton handleClick={handleClick} />
      {open && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </>
      )}
      <GridToolbarExport className="left-auto right-0" />
    </GridToolbarContainer>
  );
};

function DataList({
  columnStates,
  filters,
  updateColumns,
  updateFilters,
}: DataListProps) {
  const data = useContext(DataContext);
  const allColumns = useContext(AllColumnsContext);
  const navigate = useNavigate();

  const filterModel: GridFilterModel = { items: filters };

  function rowSelected(params: GridRowParams) {
    if (params.row.uuid != null) navigate(`../dashboard/${params.row.uuid}`);
  }

  return (
    <Box sx={{ height: "100%" }}>
      <DataGrid
        rows={data}
        getRowId={(row) => row.imo_number}
        density="comfortable"
        columns={allColumns}
        columnVisibilityModel={columnStates}
        onColumnVisibilityModelChange={(model) => {
          updateColumns(model);
        }}
        filterModel={filterModel}
        onFilterModelChange={(model) => {
          updateFilters(model.items);
        }}
        onRowClick={rowSelected}
        components={{ Toolbar }}
      />
    </Box>
  );
}

export default DataList;
