import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { MathJax } from "better-react-mathjax";

interface Props {
  equations: string[];
}

function CompEquationRenderer(props: Props) {
  console.debug("RENDERING EQUATIONS");
  return (
    <>
      <Typography
        variant="h5"
        fontWeight={500}
        fontFamily="Outfit, Roboto, Arial, sans-serif"
        className="mb-3"
      >
        Steps
      </Typography>
      <div className="flex justify-center">
        <Card className="w-11/12 mobi:min-w-full">
          <CardContent>
            <div className="flex flex-wrap flex-col">
              {props.equations.map((equation) => (
                <MathJax
                  className="my-1"
                  key={equation}
                >{`\\(\\small{${equation}}\\)`}</MathJax>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default CompEquationRenderer;
