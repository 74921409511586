import CompTimeseriesGraph from "./comp-timeseries-graph";
import Container from "@mui/material/Container";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useState } from "react";

import { generateSpeedTimeSeries } from "../../../services/fake-timeseries-gen";
import CompSegmentHeader from "./comp-segment-header";

const fakeSpeed = generateSpeedTimeSeries(20);

const GraphBox = () => {
  const minimalistOptions = {
    axisX: {
      showGrid: false,
    },
    axisY: {
      onlyInteger: true,
    },
  };
  const detailedOptions = {
    axisX: {
      showGrid: true,
    },
    axisY: {
      onlyInteger: false,
    },
  };

  const [activeSeries, setActiveSeries] = useState<String[]>(["speed"]);
  const [chartOptions, setChartOptions] = useState(minimalistOptions);

  return (
    <Container
      className="h-full"
      onMouseOver={() => setChartOptions(detailedOptions)}
      onMouseLeave={() => setChartOptions(minimalistOptions)}
    >
      <CompSegmentHeader text="Visualise" />
      <CompTimeseriesGraph
        label="Speed"
        series={fakeSpeed}
        chartOptions={chartOptions}
      />
      <ToggleButtonGroup>
        <ToggleButton value="speed">Speed</ToggleButton>
        <ToggleButton value="MGO">ROB: MGO</ToggleButton>
      </ToggleButtonGroup>
    </Container>
  );
};

export default GraphBox;
