import { randomInt } from "./utils";

export function generateTimeSeries(
  intervals: number,
  min: number,
  max: number
): number[] {
  const randomPhase = Math.random() * Math.PI * 2;
  const randomScale = intervals / Math.random();

  const timeseries = [];
  for (let i = 0; i < intervals; i++) {
    // add high frequency noise to base signal
    timeseries.push(
      (max + min) / 2 +
        randomInt(min, max) * Math.sin((i + randomPhase) / randomScale) +
        randomInt(min, max / 2) *
          Math.sin((i + randomPhase) / (randomScale / randomInt(2, 5))) +
        randomInt(min, max / 4) *
          Math.sin((i + randomPhase) / (randomScale / randomInt(2, 5)))
    );
  }
  return timeseries;
}

export function generateSpeedTimeSeries(intervals: number) {
  return generateTimeSeries(intervals, 5, 15);
}

export function fuelFromSpeed(
  speedSeries: number[],
  initialValue: number,
  factor: number
) {
  const fuelSeries = [];
  let fuel = initialValue;
  for (let i = 0; i < speedSeries.length; i++) {
    fuel = fuel - factor * speedSeries[i] ** 3;
    fuelSeries.push(fuel);
  }
  return fuelSeries;
}
