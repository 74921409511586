import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Avatar
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink, Link } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons";
import { IconSearch } from "@tabler/icons";

interface Props {
  width?: string;
  histPages: {
    name: string;
    link: string;
  }[];
  currPage: {
    name: string;
  };
}

function CompProducerHeader({ width = "100%" }: Props) {
  const theme = useTheme();

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.grey[100] }} className="shadow-none">
      <Toolbar variant="dense">
        <Typography
          fontFamily="Outfit, Arial, sans-serif"
          noWrap
          component="div"
          sx={{
            width,
            ml: `calc(100% - ${width})`,
            color: theme.palette.grey[500],
          }}
        >
          <Grid
            container
            spacing={0}
            columns={3}
            alignItems="end"
            direction="row"
          >
            <Grid item xs={1}>
              <TextField
                autoFocus
                variant="outlined"
                placeholder="Type to search"
                size="small"
                sx={{
                  backgroundColor: `${theme.palette.grey[300]}52`,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
                className="my-[20px] rounded-[8px] w-[300px]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch
                        height="14px"
                        width="14px"
                        className="opacity-100"
                        color={theme.palette.grey[500]}
                      />
                    </InputAdornment>
                  ),
                  sx: { border: "none !important" },
                }}
                inputProps={{
                  sx: {
                    fontSize: "14px",
                    color: theme.palette.grey[700],
                    "&::placeholder": {
                      opacity: 1,
                      color: theme.palette.grey[500],
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={1} justifyContent="center" className="flex-grow" />
            <Grid item xs={1} className="flex justify-end my-auto py-[20px]">
              <Avatar src="" className="w-[40px] h-[40px]" />
            </Grid>
          </Grid>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default CompProducerHeader;
