import { Routes, Route, Navigate } from "react-router-dom";

import { pages } from "./index";

function RouteProducer(props: object) {
  return (
    <Routes>
      <Route path="vessels" element={<pages.PageVesselList />} />
      <Route path="dashboard/:uuid" element={<pages.PageVesselDashboard />} />
      <Route path="world" element={<pages.PageWorldView />} />
      <Route index element={<Navigate to="vessels" />} />
    </Routes>
  );
}

export default RouteProducer;
