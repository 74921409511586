import { useState, useEffect, useContext, KeyboardEvent } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import { AllColumnsContext } from "./comp-data-fetcher";
import DataList, { Filter } from "./comp-data-list";
import TabPanel from "./comp-tab-panel";

interface TabInterface {
  label: string;
  value: number;
  columnStates: { [key: string]: boolean };
  filters: Filter[];
}

export default function TabBar() {
  const allColumns = useContext(AllColumnsContext);

  const [defaultColumnStates, setDefaultColumnStates] = useState<{
    [key: string]: boolean;
  }>({});

  const [tabs, setTabs] = useState<TabInterface[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [toggleRename, setToggleRename] = useState(false);

  useEffect(() => {
    if (allColumns.length)
      setDefaultColumnStates(
        allColumns.reduce((acc, curr) => ({ ...acc, [curr.field]: true }), {})
      );
  }, [allColumns]);

  useEffect(() => {
    if (Object.keys(defaultColumnStates).length) {
      const defaultTabs = [
        {
          label: "Default",
          value: 0,
          columnStates: defaultColumnStates,
          filters: [],
        },
      ];
      setTabs(defaultTabs);
    }
  }, [defaultColumnStates]);

  function changeTab(event: React.SyntheticEvent, newValue: number) {
    if (!isNaN(newValue)) setActiveTab(newValue);
  }

  function handleRenameToggle() {
    setToggleRename(!toggleRename);
  }
  function handleKeyPress(e: KeyboardEvent) {
    if (e.key === "Enter") handleRenameToggle();
  }

  function handleRename(event: React.ChangeEvent<HTMLInputElement>) {
    const newTabs = [...tabs];
    newTabs[activeTab].label = event.target.value;
    setTabs(newTabs);
  }

  function createTab() {
    setTabs([
      ...tabs,
      {
        label: "New Tab",
        value: tabs.length,
        columnStates: defaultColumnStates,
        filters: [],
      },
    ]);
  }

  function updateTabColumns(checkedStates: { [key: string]: boolean }) {
    const newTabs = [...tabs]; // shallow copy
    newTabs[activeTab].columnStates = checkedStates;
    setTabs(newTabs);
  }

  function updateTabFilters(filters: Filter[]) {
    const newTabs = [...tabs]; // shallow copy
    newTabs[activeTab].filters = filters;
    setTabs(newTabs);
  }

  return (
    <>
      <Box sx={{ width: "100%", height: 100 }}>
        <Tabs
          value={activeTab}
          onChange={changeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) =>
            index === activeTab ? (
              toggleRename ? (
                <div key={index}>
                  <ClickAwayListener onClickAway={handleRenameToggle}>
                    <TextField
                      variant="outlined"
                      placeholder="Rename Tab"
                      defaultValue={tab.label}
                      onKeyDown={handleKeyPress}
                      onDoubleClick={handleRenameToggle}
                      onChange={handleRename}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                    />
                  </ClickAwayListener>
                </div>
              ) : (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.value}
                  onDoubleClick={handleRenameToggle}
                />
              )
            ) : (
              <Tab key={index} label={tab.label} value={tab.value} />
            )
          )}

          <Button onClick={createTab}>+</Button>
        </Tabs>
      </Box>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={activeTab} index={index}></TabPanel>
      ))}
      {tabs.length > 0 && (
        <DataList
          columnStates={tabs[activeTab].columnStates}
          filters={tabs[activeTab].filters}
          updateFilters={updateTabFilters}
          updateColumns={updateTabColumns}
        />
      )}
    </>
  );
}
