import { pick } from "lodash";

//Linear interpolation
function lerp(
  x: number,
  x0: number,
  x1: number,
  y0: number,
  y1: number
): number {
  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
}

// Symbol for uncomputed values
// e.g. Required EEXI is not needed for low capacities, and takes the value of nothing.
type nothing = Symbol;
const nothing: nothing = Symbol("nothing");
export type Maybe<T> = T | nothing;
export const isNothing = (value: Maybe<any>): value is nothing =>
  value === nothing;

export const textFieldNames = [
  "vesselType",
  "fuelTypeMain",
  "fuelTypeAux",
] as const;
export const justNumericFieldNames = [
  "deadweight",
  "lightweight",
  "grossTonnage",
  "mcrMain",
  "mppMain",
  "mcrAux",
  "mppAux",
  "sfcMain",
  "sfcAux",
  "lpp",
  "mouldedDraught",
  "cargoCapacity",
  "breadth",
  "volumetricDisplacement",
  "referenceVelocity",
  "capacity",
  "averageVelocity",
  "marginVelocity",
  "powerMain",
  "mcrAux",
  "powerAux",
  "averageMcrMain",
  "averageMppMain",
  "displacementFroudeNumber",
  "froudeNumber",
  "blockCoefficient",
  "conversionFactorMain",
  "conversionFactorAux",
  "csrFactor",
  "cubicCapacityFactor",
  "designFactor",
  "correctionFactor",
  "attainedEexi",
] as const;
export const maybeNumericFieldNames = [
  "reductionRate",
  "requiredEexi",
] as const;

export const numericFieldNames = [
  ...justNumericFieldNames,
  ...maybeNumericFieldNames,
] as const;
export const fieldNames = [...numericFieldNames, ...textFieldNames] as const;

export type TextFieldName = typeof textFieldNames[number];
export type JustNumericFieldName = typeof justNumericFieldNames[number];
export type MaybeNumericFieldName = typeof maybeNumericFieldNames[number];
export type NumericFieldName = JustNumericFieldName | MaybeNumericFieldName;
export type FieldName = TextFieldName | NumericFieldName;

// Custom type guards
export const isTextFieldName = (name: FieldName): name is TextFieldName =>
  textFieldNames.includes(name as TextFieldName);
export const isJustNumericFieldName = (
  name: FieldName
): name is JustNumericFieldName =>
  justNumericFieldNames.includes(name as JustNumericFieldName);
export const isMaybeNumericFieldName = (
  name: FieldName
): name is MaybeNumericFieldName =>
  maybeNumericFieldNames.includes(name as MaybeNumericFieldName);
export const isFieldName = (name: string): name is FieldName =>
  fieldNames.includes(name as FieldName);

type TextFields = Record<TextFieldName, string>;
type JustNumericFields = Record<JustNumericFieldName, number>;
type MaybeNumericFields = Record<MaybeNumericFieldName, Maybe<number>>;
type NumericFields = JustNumericFields & MaybeNumericFields;

export type Fields = TextFields & NumericFields;
export type UserFieldValue = {
  value: string;
  state: "default" | "omitted";
};
export type UserFields = Partial<
  Record<keyof VesselEexiDetails["nodes"], UserFieldValue>
>;

const mathSyms: Record<NumericFieldName, string> = {
  deadweight: "{\\text{DWT}}",
  grossTonnage: "{\\text{GT}}",
  mcrMain: "{\\text{MCR}_\\text{ME}}",
  mppMain: "{\\text{MPP}_\\text{ME}}",
  mcrAux: "{\\text{MCR}_\\text{AE}}",
  mppAux: "{\\text{MPP}_\\text{AE}}",
  sfcMain: "{\\text{SFC}_\\text{ME}}",
  sfcAux: "{\\text{SFC}_\\text{AE}}",
  lightweight: "{\\text{LDT}}",
  cargoCapacity: "{\\text{Capacity}_\\text{Cargo}}",
  lpp: "{L_{pp}}",
  breadth: "{B}",
  mouldedDraught: "{T}",
  volumetricDisplacement: "{\\nabla}",
  capacity: "{\\text{Capacity}}",
  reductionRate: "{r_r}",
  requiredEexi: "{\\text{EEXI}_r}",
  attainedEexi: "{\\text{EEXI}}",
  referenceVelocity: "{v_\\text{ref}}",
  averageVelocity: "{\\bar{v}}",
  marginVelocity: "{v_\\text{margin}}",
  powerMain: "{P_\\text{ME}}",
  powerAux: "{P_\\text{AE}}",
  averageMcrMain: "{\\overline{\\text{MCR}}_\\text{ME}}",
  averageMppMain: "{\\overline{\\text{MPP}}_\\text{ME}}",
  conversionFactorMain: "{\\text{cf}_\\text{ME}}",
  conversionFactorAux: "{\\text{cf}_\\text{AE}}",
  csrFactor: "{f_\\text{CSR}}",
  cubicCapacityFactor: "{f_\\text{Cubic}}",
  designFactor: "{f_\\text{Design}}",
  correctionFactor: "{f_\\text{Corr}}",
  displacementFroudeNumber: "{\\text{Fr}_V}",
  froudeNumber: "{\\text{Fr}_L}",
  blockCoefficient: "{C_B}",
};

export const fieldNameReference: Record<FieldName, string> = {
  vesselType: "Vessel Type",
  fuelTypeMain: "Fuel Type (Main Engine)",
  fuelTypeAux: "Fuel Type (Aux Engine)",
  deadweight: "Deadweight Tonnage",
  grossTonnage: "Gross Tonnage",
  lightweight: "Lightship Displacement",
  mcrMain: "MCR (Main Engine)",
  mppMain: "MPP (Main Engine)",
  mcrAux: "MCR (Aux Engine)",
  mppAux: "MPP (Aux Engine)",
  sfcMain: "SFC (Main Engine)",
  sfcAux: "SFC (Aux Engine)",
  mouldedDraught: "Moulded Draught",
  lpp: "Length bt. Perpendiculars",
  cargoCapacity: "Cargo Capacity",
  breadth: "Moulded Breadth",
  volumetricDisplacement: "Volumetric Displacement",
  referenceVelocity: "Reference Speed",
  capacity: "Capacity",
  reductionRate: "Reduction Rate",
  averageVelocity: "Average Speed",
  marginVelocity: "Margin Speed",
  powerMain: "Power (Main Engine)",
  powerAux: "Power (Aux Engine)",
  averageMcrMain: "Average MCR (Main Engine)",
  averageMppMain: "Average MPP (Main Engine)",
  displacementFroudeNumber: "Displacement Froude Number",
  froudeNumber: "Froude Number",
  blockCoefficient: "Block Coefficient",
  conversionFactorMain: "CF (Main Engine)",
  conversionFactorAux: "CF (Aux Engine)",
  csrFactor: "CSR Correction Factor",
  cubicCapacityFactor: "Cubic Capacity Correction Factor",
  designFactor: "Design Correction Factor",
  correctionFactor: "Correction Factor",
  requiredEexi: "Required EEXI",
  attainedEexi: "Attained EEXI",
};

export const fmt = (field: NumericFieldName, value: Maybe<number>) => {
  return isNothing(value)
    ? `\\texttip{${mathSyms[field]}}{${fieldNameReference[field]}}`
    : `\\texttip{${mathSyms[field]}}{${
        fieldNameReference[field]
      } (${value.toPrecision(5)})}`;
};

type DerivedFieldFn<T> = (fields: Required<Partial<Fields>>) => {
  value: T;
  equations: string[];
};
export type FieldNode<T> =
  | { type: "onlyinput" }
  | {
      type: "optionalinput" | "derived";
      parents: readonly FieldName[];
      computation: DerivedFieldFn<T>;
    };

export type FieldNodes = Partial<
  Record<TextFieldName, FieldNode<string>> &
    Record<JustNumericFieldName, FieldNode<number>> &
    Record<MaybeNumericFieldName, FieldNode<Maybe<number>>>
>;

export type FieldValues = Partial<
  Record<TextFieldName, string> &
    Record<JustNumericFieldName, number> &
    Record<MaybeNumericFieldName, Maybe<number>>
>;

interface VesselEexiDetails {
  name: string;
  nodes: FieldNodes;
}

const commonFieldNodes: FieldNodes = {
  deadweight: {
    type: "onlyinput",
  },
  grossTonnage: {
    type: "onlyinput",
  },
  fuelTypeMain: {
    type: "onlyinput",
  },
  fuelTypeAux: {
    type: "optionalinput",
    parents: ["fuelTypeMain"] as const,
    computation: ({ fuelTypeMain }) => {
      const fuelTypeAux = fuelTypeMain;
      return { value: fuelTypeAux, equations: [] };
    },
  },
  conversionFactorMain: {
    type: "derived",
    parents: ["fuelTypeMain"] as const,
    computation: ({ fuelTypeMain }) => {
      const conversionFactorMain =
        fuelMasterReference[fuelTypeMain].conversionFactor;
      const equations = [
        `${fmt(
          "conversionFactorMain",
          conversionFactorMain
        )}=${conversionFactorMain}`,
      ];
      return { value: conversionFactorMain, equations };
    },
  },
  conversionFactorAux: {
    type: "derived",
    parents: ["fuelTypeAux"] as const,
    computation: ({ fuelTypeAux }) => {
      const conversionFactorAux =
        fuelMasterReference[fuelTypeAux].conversionFactor;
      const equations = [
        `${fmt(
          "conversionFactorAux",
          conversionFactorAux
        )}=${conversionFactorAux}`,
      ];
      return { value: conversionFactorAux, equations };
    },
  },
  mcrMain: {
    type: "onlyinput",
  },
  mppMain: {
    type: "onlyinput",
  },
  mcrAux: {
    type: "optionalinput",
    parents: ["mcrMain"] as const,
    computation: ({ mcrMain }) => {
      const mcrAux = mcrMain >= 10000 ? 0.05 * mcrMain + 500 : 0.1 * mcrMain;
      const equations = [
        `${fmt("mcrAux", mcrAux)}=` +
          (mcrMain >= 10000
            ? `0.05\\times${fmt("mcrMain", mcrMain)}+500`
            : `0.1\\times${fmt("mcrMain", mcrMain)}`) +
          `=${mcrAux.toPrecision(5)}`,
      ];
      return { value: mcrAux, equations };
    },
  },
  mppAux: {
    type: "optionalinput",
    parents: ["mppMain"] as const,
    computation: ({ mppMain }) => {
      const mppAux = mppMain >= 10000 ? 0.05 * mppMain + 500 : 0.1 * mppMain;
      const equations = [
        `${fmt("mppAux", mppAux)}=` +
          (mppMain >= 10000
            ? `0.05\\times${fmt("mcrMain", mppMain)}+500`
            : `0.1\\times${fmt("mcrMain", mppMain)}`) +
          `=${mppAux.toPrecision(5)}`,
      ];
      return { value: mppAux, equations };
    },
  },
  powerMain: {
    type: "derived",
    parents: ["mcrMain"] as const,
    computation: ({ mcrMain }) => {
      const powerMain = 0.75 * mcrMain;
      const equations = [
        `${fmt("powerMain", powerMain)}=0.75\\times${fmt(
          "mcrMain",
          mcrMain
        )}=${powerMain.toPrecision(5)}`,
      ];
      return { value: powerMain, equations };
    },
  },
  powerAux: {
    type: "derived",
    parents: ["mcrAux"] as const,
    computation: ({ mcrAux }) => {
      const powerAux = 0.5 * mcrAux;
      const equations = [
        `${fmt("powerAux", powerAux)}=0.5\\times${fmt(
          "mcrAux",
          mcrAux
        )}=${powerAux.toPrecision(5)}`,
      ];
      return { value: powerAux, equations };
    },
  },
  sfcMain: {
    type: "optionalinput",
    parents: [],
    computation: ({}) => {
      const sfcMain = 190;
      const equations = [`${fmt("sfcMain", sfcMain)}=190`];
      return { value: sfcMain, equations };
    },
  },
  sfcAux: {
    type: "optionalinput",
    parents: [],
    computation: ({}) => {
      const sfcAux = 215;
      const equations = [`${fmt("sfcAux", sfcAux)}=215`];
      return { value: sfcAux, equations };
    },
  },
  marginVelocity: {
    type: "derived",
    parents: ["averageVelocity"] as const,
    computation: ({ averageVelocity }) => {
      const marginVelocity = Math.min(1, averageVelocity * 0.05);
      const equations = [
        `${fmt("marginVelocity", marginVelocity)}=\\min(1,0.05\\times${fmt(
          "averageVelocity",
          averageVelocity
        )})=${marginVelocity.toPrecision(5)}`,
      ];
      return { value: marginVelocity, equations };
    },
  },
  referenceVelocity: {
    type: "optionalinput",
    parents: [
      "averageMcrMain",
      "mcrMain",
      "averageVelocity",
      "marginVelocity",
    ] as const,
    computation: ({
      averageMcrMain,
      mcrMain,
      averageVelocity,
      marginVelocity,
    }) => {
      const referenceVelocity =
        (averageVelocity - marginVelocity) *
        (mcrMain / averageMcrMain) ** (1 / 3);
      const equations = [
        `${fmt("referenceVelocity", referenceVelocity)}=(${fmt(
          "averageVelocity",
          averageVelocity
        )}-${fmt("marginVelocity", marginVelocity)})\\times\\left(\\dfrac{${fmt(
          "mcrMain",
          mcrMain
        )}}{${fmt(
          "averageMcrMain",
          averageMcrMain
        )}}\\right)^\\frac{1}{3}=${referenceVelocity.toPrecision(5)}`,
      ];
      return { value: referenceVelocity, equations };
    },
  },
  lightweight: {
    type: "onlyinput",
  },
  csrFactor: {
    type: "derived",
    parents: ["deadweight", "lightweight"] as const,
    computation: ({ deadweight, lightweight }) => {
      const csrFactor = 1 + 0.08 * (lightweight / deadweight);
      const equations = [
        `${fmt("csrFactor", csrFactor)}=1+0.08\\times\\dfrac{${fmt(
          "lightweight",
          lightweight
        )}}{${fmt("deadweight", deadweight)}}=${csrFactor.toPrecision(5)}`,
      ];
      return { value: csrFactor, equations };
    },
  },
  cargoCapacity: {
    type: "optionalinput",
    parents: [],
    computation: ({}) => {
      const cargoCapacity = 0;
      const equations = [
        `${fmt("cargoCapacity", cargoCapacity)}=${cargoCapacity}`,
      ];
      return { value: cargoCapacity, equations };
    },
  },
  volumetricDisplacement: {
    type: "onlyinput",
  },
  lpp: {
    type: "onlyinput",
  },
  breadth: {
    type: "onlyinput",
  },
  mouldedDraught: {
    type: "onlyinput",
  },
  displacementFroudeNumber: {
    type: "derived",
    parents: ["referenceVelocity", "volumetricDisplacement"] as const,
    computation: ({ referenceVelocity, volumetricDisplacement }) => {
      const displacementFroudeNumber = Math.min(
        0.6,
        (0.5144 * referenceVelocity) /
          (9.81 * volumetricDisplacement ** (1 / 3)) ** 0.5
      );
      const equations = [
        `${fmt(
          "displacementFroudeNumber",
          displacementFroudeNumber
        )}=\\min\\left(0.6,\\dfrac{0.5144\\times${fmt(
          "referenceVelocity",
          referenceVelocity
        )}}{\\sqrt{9.81\\times\\sqrt[3]{${fmt(
          "volumetricDisplacement",
          volumetricDisplacement
        )}}}}\\right)=${displacementFroudeNumber.toPrecision(5)}`,
      ];
      return { value: displacementFroudeNumber, equations };
    },
  },
  blockCoefficient: {
    type: "optionalinput",
    parents: [
      "volumetricDisplacement",
      "lpp",
      "breadth",
      "mouldedDraught",
    ] as const,
    computation: ({ volumetricDisplacement, lpp, breadth, mouldedDraught }) => {
      const blockCoefficient =
        volumetricDisplacement / (lpp * breadth * mouldedDraught);
      const equations = [
        `${fmt("blockCoefficient", blockCoefficient)}=\\dfrac{${fmt(
          "volumetricDisplacement",
          volumetricDisplacement
        )}}{${fmt("lpp", lpp)}\\times${fmt("breadth", breadth)}\\times${fmt(
          "mouldedDraught",
          mouldedDraught
        )}}=${blockCoefficient.toPrecision(5)}`,
      ];
      return { value: blockCoefficient, equations };
    },
  },
  froudeNumber: {
    type: "derived",
    parents: ["referenceVelocity", "lpp"] as const,
    computation: ({ referenceVelocity, lpp }) => {
      const froudeNumber = (0.5144 * referenceVelocity) / (9.81 * lpp) ** 0.5;
      const equations = [
        `${fmt("froudeNumber", froudeNumber)}=\\dfrac{0.5144\\times${fmt(
          "referenceVelocity",
          referenceVelocity
        )}}{\\sqrt{9.81\\times${fmt("lpp", lpp)}}}=${froudeNumber.toPrecision(
          5
        )}`,
      ];
      return { value: froudeNumber, equations };
    },
  },
  attainedEexi: {
    type: "derived",
    parents: [
      "powerMain",
      "sfcMain",
      "conversionFactorMain",
      "powerAux",
      "sfcAux",
      "conversionFactorAux",
      "correctionFactor",
      "capacity",
      "referenceVelocity",
    ] as const,
    computation: ({
      powerMain,
      sfcMain,
      conversionFactorMain,
      powerAux,
      sfcAux,
      conversionFactorAux,
      correctionFactor,
      capacity,
      referenceVelocity,
    }) => {
      const attainedEexi =
        (powerMain * sfcMain * conversionFactorMain +
          powerAux * sfcAux * conversionFactorAux) /
        (correctionFactor * capacity * referenceVelocity);
      const equations = [
        `${fmt("attainedEexi", attainedEexi)}=\\dfrac{${fmt(
          "powerMain",
          powerMain
        )}\\times${fmt("sfcMain", sfcMain)}\\times ${fmt(
          "conversionFactorMain",
          conversionFactorMain
        )}+${fmt("powerAux", powerAux)}\\times${fmt(
          "sfcAux",
          sfcAux
        )}\\times ${fmt("conversionFactorAux", conversionFactorAux)}}{${fmt(
          "correctionFactor",
          correctionFactor
        )}\\times${fmt("capacity", capacity)}\\times ${fmt(
          "referenceVelocity",
          referenceVelocity
        )}}=${attainedEexi.toPrecision(5)}`,
      ];
      return { value: attainedEexi, equations };
    },
  },
};

export const vesselMasterReference: Readonly<
  Record<string, VesselEexiDetails>
> = {
  bulkCarrier: {
    name: "Bulk Carrier",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "lightweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "cargoCapacity",
        "csrFactor",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 23.751 * deadweight ** 0.54087;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=23.751\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.54087}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${deadweight}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 200000
              ? 0.15
              : deadweight >= 20000
              ? 0.2
              : deadweight > 10000
              ? (deadweight - 10000) / 50000
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 961.79 *
              Math.min(279000, deadweight) ** -0.477 *
              (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt(
                  "requiredEexi",
                  requiredEexi
                )}=961.79\\times\\min\\left(279000,${fmt(
                  "deadweight",
                  deadweight
                )}\\right)^{-0.477}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 10.6585 * deadweight ** 0.02706;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=10.6585\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.02706}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      cubicCapacityFactor: {
        type: "derived",
        parents: ["deadweight", "cargoCapacity"] as const,
        computation: ({ deadweight, cargoCapacity }) => {
          const cubicCapacityFactor =
            !cargoCapacity || deadweight / cargoCapacity >= 0.55
              ? 1
              : (deadweight / cargoCapacity) ** -0.15;
          const equations = [
            `${fmt("cubicCapacityFactor", cubicCapacityFactor)}=` +
              (!cargoCapacity || deadweight / cargoCapacity >= 0.55
                ? `1`
                : `\\left(\\dfrac{${fmt("deadweight", deadweight)}}{${fmt(
                    "cargoCapacity",
                    cargoCapacity
                  )}}\\right)^{-0.15}=${cubicCapacityFactor.toPrecision(5)}`),
          ];
          return { value: cubicCapacityFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["csrFactor", "cubicCapacityFactor"] as const,
        computation: ({ csrFactor, cubicCapacityFactor }) => {
          const correctionFactor = csrFactor * cubicCapacityFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "csrFactor",
              csrFactor
            )}\\times${fmt(
              "cubicCapacityFactor",
              cubicCapacityFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  gasCarrier: {
    name: "Gas Carrier",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "cargoCapacity",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 21.4704 * deadweight ** 0.59522;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=21.4704\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.59522}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${deadweight}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 15000
              ? 0.3
              : deadweight >= 10000
              ? 0.2
              : deadweight > 2000
              ? lerp(deadweight, 2000, 10000, 0, 0.2)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 1120 * deadweight ** -0.456 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=1120\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.456}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toFixed(3)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 7.4462 * deadweight ** 0.07604;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=7.4462\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.07604}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      cubicCapacityFactor: {
        type: "derived",
        parents: ["deadweight", "cargoCapacity"] as const,
        computation: ({ deadweight, cargoCapacity }) => {
          const cubicCapacityFactor = !cargoCapacity
            ? 1
            : (deadweight / cargoCapacity) ** -0.56;
          const equations = [
            `${fmt("cubicCapacityFactor", cubicCapacityFactor)}=` +
              (!cargoCapacity
                ? `1`
                : `\\dfrac{${fmt("deadweight", deadweight)}}{${fmt(
                    "cargoCapacity",
                    cargoCapacity
                  )}}^{-0.56}=${cubicCapacityFactor}`),
          ];
          return { value: cubicCapacityFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["cubicCapacityFactor"] as const,
        computation: ({ cubicCapacityFactor }) => {
          const correctionFactor = cubicCapacityFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "cubicCapacityFactor",
              cubicCapacityFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  tanker: {
    name: "Tanker",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "lightweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "cargoCapacity",
        "csrFactor",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 22.8415 * deadweight ** 0.55826;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=22.8415\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.55826}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${deadweight}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 200000
              ? 0.15
              : deadweight >= 20000
              ? 0.2
              : deadweight > 4000
              ? lerp(deadweight, 4000, 20000, 0, 0.2)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 1218.8 * deadweight ** -0.488 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=1218.8\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.488}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toFixed(3)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 8.1358 * deadweight ** 0.05383;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=8.1358\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.05383}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      cubicCapacityFactor: {
        type: "derived",
        parents: ["deadweight", "cargoCapacity"] as const,
        computation: ({ deadweight, cargoCapacity }) => {
          const cubicCapacityFactor =
            !cargoCapacity || deadweight / cargoCapacity >= 0.98
              ? 1
              : (deadweight / cargoCapacity) ** -0.7 - 0.014;
          const equations = [
            `${fmt("cubicCapacityFactor", cubicCapacityFactor)}=` +
              (!cargoCapacity || deadweight / cargoCapacity >= 0.98
                ? `1`
                : `\\left(\\dfrac{${fmt("deadweight", deadweight)}}{${fmt(
                    "cargoCapacity",
                    cargoCapacity
                  )}}\\right)^{-0.7}-0.014=${cubicCapacityFactor.toPrecision(
                    5
                  )}`),
          ];
          return { value: cubicCapacityFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["csrFactor", "cubicCapacityFactor"] as const,
        computation: ({ csrFactor, cubicCapacityFactor }) => {
          const correctionFactor = csrFactor * cubicCapacityFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "csrFactor",
              csrFactor
            )}\\times${fmt(
              "cubicCapacityFactor",
              cubicCapacityFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  containerShip: {
    name: "Container Ship",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain =
            0.5042 * Math.min(95000, deadweight) ** 1.03046;
          const equations = [
            `${fmt(
              "averageMcrMain",
              averageMcrMain
            )}=0.5042\\times\\min\\left(95000,${fmt(
              "deadweight",
              deadweight
            )}\\right)^{1.03046}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight * 0.7;
          const equations = [
            `${fmt("capacity", capacity)}=0.7\\times${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 200000
              ? 0.5
              : deadweight >= 120000
              ? 0.45
              : deadweight >= 80000
              ? 0.35
              : deadweight >= 40000
              ? 0.3
              : deadweight >= 15000
              ? 0.2
              : deadweight > 10000
              ? lerp(deadweight, 10000, 15000, 0, 0.2)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 174.22 * deadweight ** -0.201 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=174.22\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.201}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity =
            3.2395 * Math.min(80000, deadweight) ** 0.18294;
          const equations = [
            `${fmt(
              "averageVelocity",
              averageVelocity
            )}=3.2395\\times\\min\\left(80000,${fmt(
              "deadweight",
              deadweight
            )}\\right)^{0.18294}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: [],
        computation: ({}) => {
          const correctionFactor = 1;
          const equations = [`${fmt("correctionFactor", correctionFactor)}=1`];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  generalCargoShip: {
    name: "General Cargo Ship",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "volumetricDisplacement",
        "lpp",
        "breadth",
        "mouldedDraught",
        "displacementFroudeNumber",
        "blockCoefficient",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 0.8816 * deadweight ** 0.9205;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=0.8816\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.9205}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 15000
              ? 0.3
              : deadweight > 3000
              ? lerp(deadweight, 3000, 15000, 0, 0.3)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 107.48 * deadweight ** -0.216 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=107.48\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.216}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 2.4538 * deadweight ** 0.18832;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=2.4538\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.19932}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      designFactor: {
        type: "derived",
        parents: ["displacementFroudeNumber", "blockCoefficient"] as const,
        computation: ({ displacementFroudeNumber, blockCoefficient }) => {
          const designFactor = Math.min(
            1,
            0.174 / (displacementFroudeNumber ** 2.3 * blockCoefficient ** 0.3)
          );
          const equations = [
            `${fmt(
              "designFactor",
              designFactor
            )}=\\min\\left(1,\\dfrac{0.174}{${fmt(
              "displacementFroudeNumber",
              displacementFroudeNumber
            )}^{2.3}\\times${fmt(
              "blockCoefficient",
              blockCoefficient
            )}^{0.3}}\\right)=${designFactor.toPrecision(5)}`,
          ];
          return { value: designFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["designFactor"] as const,
        computation: ({ designFactor }) => {
          const correctionFactor = designFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "designFactor",
              designFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  refrigeratedCargoCarrier: {
    name: "Refrigerated Cargo Carrier",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 0.0272 * deadweight ** 1.38634;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=0.0272\\times${fmt(
              "deadweight",
              deadweight
            )}^{1.38634}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 5000
              ? 0.15
              : deadweight > 3000
              ? lerp(deadweight, 3000, 5000, 0, 0.15)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 227.01 * deadweight ** -0.244 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=227.01\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.244}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 1.06 * deadweight ** 0.31518;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=1.06\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.31518}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: [],
        computation: ({}) => {
          const correctionFactor = 1;
          const equations = [`${fmt("correctionFactor", correctionFactor)}=1`];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  combinationCarrier: {
    name: "Combination Carrier",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 22.8536 * deadweight ** 0.5582;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=22.8536\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.5582}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 20000
              ? 0.2
              : deadweight > 4000
              ? lerp(deadweight, 4000, 20000, 0, 0.2)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 1219.0 * deadweight ** -0.488 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=1219.0\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.488}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 8.1391 * deadweight ** 0.05378;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=8.1391\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.05378}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: [],
        computation: ({}) => {
          const correctionFactor = 1;
          const equations = [`${fmt("correctionFactor", correctionFactor)}=1`];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  lngCarrier: {
    name: "LNG Carrier",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 20.7096 * deadweight ** 0.63477;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=20.7096\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.63477}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 20000
              ? 0.2
              : deadweight > 4000
              ? lerp(deadweight, 4000, 20000, 0, 0.2)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 2253.7 * deadweight ** -0.474 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=2253.7\\times${fmt(
                  "deadweight",
                  deadweight
                )}^{-0.474}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 11.0536 * deadweight ** 0.0503;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=11.0536\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.0503}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: [],
        computation: ({}) => {
          const correctionFactor = 1;
          const equations = [`${fmt("correctionFactor", correctionFactor)}=1`];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  roRoCargoShipVc: {
    name: "Ro-Ro Cargo Ship (Vehicle Carrier)",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "grossTonnage",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "volumetricDisplacement",
        "lpp",
        "breadth",
        "mouldedDraught",
        "froudeNumber",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 262.7693 * deadweight ** 0.39973;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=262.7693\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.39973}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate = deadweight >= 10000 ? 0.15 : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "grossTonnage", "reductionRate"] as const,
        computation: ({ deadweight, grossTonnage, reductionRate }) => {
          const dgRatio = deadweight / grossTonnage;
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : (dgRatio < 0.3 ? 780.36 * dgRatio ** -0.7 : 1812.63) *
              deadweight ** -0.471 *
              (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=` +
                  (dgRatio < 0.3
                    ? `780.36\\times\\left(\\dfrac{${fmt(
                        "deadweight",
                        deadweight
                      )}}{${fmt(
                        "grossTonnage",
                        grossTonnage
                      )}}\\right)^{-0.471}`
                    : `1812.63`) +
                  `\\times${fmt(
                    "deadweight",
                    deadweight
                  )}^{-0.474}\\times(1-${fmt(
                    "reductionRate",
                    reductionRate
                  )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 16.6773 * deadweight ** 0.01802;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=16.6773\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.01802}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      designFactor: {
        type: "derived",
        parents: [
          "froudeNumber",
          "volumetricDisplacement",
          "lpp",
          "breadth",
          "mouldedDraught",
        ] as const,
        computation: ({
          froudeNumber,
          volumetricDisplacement,
          lpp,
          breadth,
          mouldedDraught,
        }) => {
          const designFactor = Math.min(
            1,
            1 /
              (froudeNumber ** 2 *
                (lpp / breadth) ** 0.5 *
                (breadth / mouldedDraught) ** 0.75 *
                (lpp / volumetricDisplacement ** (1 / 3)))
          );
          const equations = [
            `${fmt(
              "designFactor",
              designFactor
            )}=\\min\\left(1,\\dfrac{1}{${fmt(
              "froudeNumber",
              froudeNumber
            )}^{2}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "breadth",
              breadth
            )}}\\right)^{0.5}\\times\\left(\\frac{${fmt(
              "breadth",
              breadth
            )}}{${fmt(
              "mouldedDraught",
              mouldedDraught
            )}}\\right)^{0.75}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "volumetricDisplacement",
              volumetricDisplacement
            )}}\\right)^{\\frac{1}{3}}}\\right)=${designFactor.toPrecision(5)}`,
          ];
          return { value: designFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["designFactor"] as const,
        computation: ({ designFactor }) => {
          const correctionFactor = designFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "designFactor",
              designFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  roRoCargoShip: {
    name: "Ro-Ro Cargo Ship",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "volumetricDisplacement",
        "lpp",
        "breadth",
        "mouldedDraught",
        "froudeNumber",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 37.7708 * deadweight ** 0.6345;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=37.7708\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.6345}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 2000
              ? 0.05
              : deadweight > 1000
              ? lerp(deadweight, 1000, 2000, 0, 0.05)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 1686.17 *
              Math.min(17000, deadweight) ** -0.498 *
              (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt(
                  "requiredEexi",
                  requiredEexi
                )}=1686.17\\times\\min\\left(17000,${fmt(
                  "deadweight",
                  deadweight
                )}\\right)^{-0.498}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 8.0793 * deadweight ** 0.09123;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=8.0793\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.09123}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      designFactor: {
        type: "derived",
        parents: [
          "froudeNumber",
          "volumetricDisplacement",
          "lpp",
          "breadth",
          "mouldedDraught",
        ] as const,
        computation: ({
          froudeNumber,
          volumetricDisplacement,
          lpp,
          breadth,
          mouldedDraught,
        }) => {
          const designFactor = Math.min(
            1,
            1 /
              (froudeNumber ** 2 *
                (lpp / breadth) ** 0.5 *
                (breadth / mouldedDraught) ** 0.75 *
                (lpp / volumetricDisplacement ** (1 / 3)))
          );
          const equations = [
            `${fmt(
              "designFactor",
              designFactor
            )}=\\min\\left(1,\\dfrac{1}{${fmt(
              "froudeNumber",
              froudeNumber
            )}^{2}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "breadth",
              breadth
            )}}\\right)^{0.5}\\times\\left(\\frac{${fmt(
              "breadth",
              breadth
            )}}{${fmt(
              "mouldedDraught",
              mouldedDraught
            )}}\\right)^{0.75}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "volumetricDisplacement",
              volumetricDisplacement
            )}}\\right)^{\\frac{1}{3}}}\\right)=${designFactor.toPrecision(5)}`,
          ];
          return { value: designFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["designFactor"] as const,
        computation: ({ designFactor }) => {
          const correctionFactor = designFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "designFactor",
              designFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  roRoPassengerShip: {
    name: "Ro-Ro Passenger Ship",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "mcrMain",
        "powerMain",
        "mcrAux",
        "powerAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "referenceVelocity",
        "volumetricDisplacement",
        "lpp",
        "breadth",
        "mouldedDraught",
        "froudeNumber",
        "attainedEexi",
      ]),
      averageMcrMain: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageMcrMain = 9.1338 * deadweight ** 0.91116;
          const equations = [
            `${fmt("averageMcrMain", averageMcrMain)}=9.1338\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.91116}=${averageMcrMain.toPrecision(5)}`,
          ];
          return { value: averageMcrMain, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const capacity = deadweight;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "deadweight",
              deadweight
            )}=${capacity}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const reductionRate =
            deadweight >= 1000
              ? 0.05
              : deadweight > 250
              ? lerp(deadweight, 250, 1000, 0, 0.05)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["deadweight", "reductionRate"] as const,
        computation: ({ deadweight, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 902.59 *
              Math.min(10000, deadweight) ** -0.381 *
              (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt(
                  "requiredEexi",
                  requiredEexi
                )}=902.59\\times\\min\\left(10000,${fmt(
                  "deadweight",
                  deadweight
                )}\\right)^{-0.381}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["deadweight"] as const,
        computation: ({ deadweight }) => {
          const averageVelocity = 4.114 * deadweight ** 0.19863;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=4.114\\times${fmt(
              "deadweight",
              deadweight
            )}^{0.19863}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      designFactor: {
        type: "derived",
        parents: [
          "froudeNumber",
          "volumetricDisplacement",
          "lpp",
          "breadth",
          "mouldedDraught",
        ] as const,
        computation: ({
          froudeNumber,
          volumetricDisplacement,
          lpp,
          breadth,
          mouldedDraught,
        }) => {
          const designFactor = Math.min(
            1,
            1 /
              (froudeNumber ** 2.5 *
                (lpp / breadth) ** 0.75 *
                (breadth / mouldedDraught) ** 0.75 *
                (lpp / volumetricDisplacement ** (1 / 3)))
          );
          const equations = [
            `${fmt(
              "designFactor",
              designFactor
            )}=\\min\\left(1,\\dfrac{1}{${fmt(
              "froudeNumber",
              froudeNumber
            )}^{2.5}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "breadth",
              breadth
            )}}\\right)^{0.75}\\times\\left(\\frac{${fmt(
              "breadth",
              breadth
            )}}{${fmt(
              "mouldedDraught",
              mouldedDraught
            )}}\\right)^{0.75}\\times\\left(\\frac{${fmt("lpp", lpp)}}{${fmt(
              "volumetricDisplacement",
              volumetricDisplacement
            )}}\\right)^{\\frac{1}{3}}}\\right)=${designFactor.toPrecision(5)}`,
          ];
          return { value: designFactor, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: ["designFactor"] as const,
        computation: ({ designFactor }) => {
          const correctionFactor = designFactor;
          const equations = [
            `${fmt("correctionFactor", correctionFactor)}=${fmt(
              "designFactor",
              designFactor
            )}=${correctionFactor.toPrecision(5)}`,
          ];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
  cruisePassengerShip: {
    name: "Cruise Passenger Ship",
    nodes: {
      ...pick(commonFieldNodes, [
        "deadweight",
        "grossTonnage",
        "fuelTypeMain",
        "fuelTypeAux",
        "conversionFactorMain",
        "conversionFactorAux",
        "sfcMain",
        "sfcAux",
        "marginVelocity",
        "attainedEexi",
      ]),
      mppMain: {
        type: "onlyinput",
      },
      mppAux: {
        type: "optionalinput",
        parents: ["mppMain"] as const,
        computation: ({ mppMain }) => {
          const mppAux =
            mppMain >= 10000 ? 0.05 * mppMain + 500 : 0.1 * mppMain;
          const equations = [
            `${fmt("mppAux", mppAux)}=` +
              (mppMain != null && mppMain >= 10000
                ? `0.05\\times${fmt("mppMain", mppMain)}+500`
                : `0.1\\times${fmt("mppMain", mppMain)}`) +
              `=${mppAux.toPrecision(5)}`,
          ];
          return { value: mppAux, equations };
        },
      },
      averageMppMain: {
        type: "derived",
        parents: ["grossTonnage"] as const,
        computation: ({ grossTonnage }) => {
          const averageMppMain = 1.355 * grossTonnage ** 0.88664;
          const equations = [
            `${fmt("averageMppMain", averageMppMain)}=1.355\\times${fmt(
              "grossTonnage",
              grossTonnage
            )}^{0.88664}=${averageMppMain.toPrecision(5)}`,
          ];
          return { value: averageMppMain, equations };
        },
      },
      powerMain: {
        type: "derived",
        parents: ["mppMain"] as const,
        computation: ({ mppMain }) => {
          const powerMain = 0.75 * mppMain;
          const equations = [
            `${fmt("powerMain", powerMain)}=0.75\\times${fmt(
              "mppMain",
              mppMain
            )}=${powerMain.toPrecision(5)}`,
          ];
          return { value: powerMain, equations };
        },
      },
      powerAux: {
        type: "derived",
        parents: ["mppAux"] as const,
        computation: ({ mppAux }) => {
          const powerAux = 0.5 * mppAux;
          const equations = [
            `${fmt("powerAux", powerAux)}=0.5\\times${fmt(
              "mppAux",
              mppAux
            )}=${powerAux.toPrecision(5)}`,
          ];
          return { value: powerAux, equations };
        },
      },
      referenceVelocity: {
        type: "optionalinput",
        parents: [
          "averageMppMain",
          "mppMain",
          "averageVelocity",
          "marginVelocity",
        ] as const,
        computation: ({
          averageMppMain,
          mppMain,
          averageVelocity,
          marginVelocity,
        }) => {
          const referenceVelocity =
            (averageVelocity - marginVelocity) *
            (mppMain / averageMppMain) ** (1 / 3);
          const equations = [
            `${fmt("referenceVelocity", referenceVelocity)}=(${fmt(
              "averageVelocity",
              averageVelocity
            )}-${fmt(
              "marginVelocity",
              marginVelocity
            )})\\times\\left(\\dfrac{${fmt("mppMain", mppMain)}}{${fmt(
              "averageMppMain",
              averageMppMain
            )}}\\right)^\\frac{1}{3}=${referenceVelocity.toPrecision(5)}`,
          ];
          return { value: referenceVelocity, equations };
        },
      },
      capacity: {
        type: "derived",
        parents: ["grossTonnage"] as const,
        computation: ({ grossTonnage }) => {
          const capacity = grossTonnage;
          const equations = [
            `${fmt("capacity", capacity)}=${fmt(
              "grossTonnage",
              grossTonnage
            )}=${grossTonnage}`,
          ];
          return { value: capacity, equations };
        },
      },
      reductionRate: {
        type: "derived",
        parents: ["grossTonnage"] as const,
        computation: ({ grossTonnage }) => {
          const reductionRate =
            grossTonnage >= 85000
              ? 0.3
              : grossTonnage > 25000
              ? lerp(grossTonnage, 25000, 85000, 0, 0.3)
              : nothing;
          const equations = isNothing(reductionRate)
            ? []
            : [
                `${fmt(
                  "reductionRate",
                  reductionRate
                )}=${reductionRate.toPrecision(5)}`,
              ];
          return { value: reductionRate, equations };
        },
      },
      requiredEexi: {
        type: "derived",
        parents: ["grossTonnage", "reductionRate"] as const,
        computation: ({ grossTonnage, reductionRate }) => {
          const requiredEexi = isNothing(reductionRate)
            ? nothing
            : 170.84 * grossTonnage ** -0.214 * (1 - reductionRate);
          const equations = isNothing(requiredEexi)
            ? []
            : [
                `${fmt("requiredEexi", requiredEexi)}=170.84\\times${fmt(
                  "grossTonnage",
                  grossTonnage
                )}^{-0.214}\\times(1-${fmt(
                  "reductionRate",
                  reductionRate
                )})=${requiredEexi?.toPrecision(5)}`,
              ];
          return { value: requiredEexi, equations };
        },
      },
      averageVelocity: {
        type: "optionalinput",
        parents: ["grossTonnage"] as const,
        computation: ({ grossTonnage }) => {
          const averageVelocity = 5.124 * grossTonnage ** 0.12714;
          const equations = [
            `${fmt("averageVelocity", averageVelocity)}=5.124\\times${fmt(
              "grossTonnage",
              grossTonnage
            )}^{0.12714}=${averageVelocity.toPrecision(5)}`,
          ];
          return { value: averageVelocity, equations };
        },
      },
      correctionFactor: {
        type: "derived",
        parents: [],
        computation: ({}) => {
          const correctionFactor = 1;
          const equations = [`${fmt("correctionFactor", correctionFactor)}=1`];
          return { value: correctionFactor, equations };
        },
      },
    },
  },
};

export const fuelMasterReference: Record<
  string,
  {
    fullName: string;
    name: string;
    carbonContent: number;
    conversionFactor: number;
  }
> = {
  mgo: {
    fullName: "Diesel/Gas Oil",
    name: "MGO",
    carbonContent: 0.8744,
    conversionFactor: 3.206,
  },
  lfo: {
    fullName: "Light Fuel Oil",
    name: "LFO",
    carbonContent: 0.8594,
    conversionFactor: 3.15104,
  },
  hfo: {
    fullName: "Heavy Fuel Oil",
    name: "HFO",
    carbonContent: 0.8493,
    conversionFactor: 3.114,
  },
  lpgPropane: {
    fullName: "Liquefied Petroleum Gas: Propane",
    name: "LPG: Propane",
    carbonContent: 0.8182,
    conversionFactor: 3.0,
  },
  lpgButane: {
    fullName: "Liquefied Petroleum Gas: Butane",
    name: "LPG: Butane",
    carbonContent: 0.8264,
    conversionFactor: 3.03,
  },
  lng: {
    fullName: "Liquefied Natural Gas",
    name: "LNG",
    carbonContent: 0.75,
    conversionFactor: 2.75,
  },
  methanol: {
    fullName: "Methanol",
    name: "Methanol",
    carbonContent: 0.375,
    conversionFactor: 1.375,
  },
  ethanol: {
    fullName: "Ethanol",
    name: "Ethanol",
    carbonContent: 0.5217,
    conversionFactor: 1.913,
  },
};
